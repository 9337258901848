import { Modal, Form, Input} from 'antd';


export default function AddSection({visible, onCreate, onCancel}) {
        const [form] = Form.useForm();
        const validateMessages = {
            required: "${label} is required!"
        };

        return(
            <div>
                <Modal
            visible={visible}
            title="Add Section"
            okText="Add"
            cancelText="Cancel"
            onCancel={onCancel}
            onOk={() => {
                form
                .validateFields()
                .then((values) => {
                    form.resetFields();
                    onCreate(values);
                })
                .catch((info) => {
                    console.log('Validate Failed:', info);
                });
            }}
            >
          <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            validateMessages={validateMessages}
            initialValues={{
              modifier: 'public',
            }}
          >
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  message: 'Please input the sectionName!',
                }
              ]}>
                <Input />
            </Form.Item>
          
          </Form>
        </Modal>
            </div>
        )
}