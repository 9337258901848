import React, { useState } from "react";
import {  Modal, Form, Input, Select } from 'antd';

const { Option } = Select;

export default function AddEmployees({visible, onCreate, onCancel, company}) {
    const [form] = Form.useForm();
    const [getBranchsId] = useState(company.branches);

    const validateMessages = {
        required: "${label} is required!",
        types: {
            email: "${label} is not a valid email!",
            number: "${label} is not a valid number!",
            text: "${label} is not a valid text!",    },
        number: {
            range: "${label} must be between ${min} and ${max}",
        },
    };


    return(
        <div>
            <Modal
        visible={visible}
        title="Add Employee"
        okText="Add"
        cancelText="Cancel"
        onCancel={onCancel}
        onOk={() => {
            form
            .validateFields()
            .then((values) => {
                form.resetFields();
                onCreate(values);
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
        }}
        >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        validateMessages={validateMessages}
        initialValues={{
          modifier: 'public',
        }}
      >
        <Form.Item
          name="fullName"
          label="FullName"
          rules={[
            {
              required: true,
              message: 'Please input the fullName!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        
        <Form.Item
            
            name="mobile"
            label="Mobile"
            rules={[{
                    max:10,
                    min:10,
                    message: "Value should be less equal 10 number",
                },{ 
                    required: true, message: 'Please input branch mobile number!' }]}
            >
            <Input   />
        </Form.Item>

         <Form.Item
            name="email"
            label="Email"
            rules={[
            {
                type: "email",
                message: "The input is not valid E-mail!",
            },
            {
                required: true,
                message: "Please input your E-mail!",
            },
            ]}
        >
        <Input 
        placeholder="E-mail"
        />
        </Form.Item>

        <Form.Item
            name="password"
            label="Password"
            rules={[
            {
                required: true,
                message: "Please input your Password!",
            },
            ]}
        >
            <Input
            type="password"
            placeholder="Password"
            />
        </Form.Item>

        <Form.Item label="Branch">
            <Form.Item name="branche_id" noStyle
            rules={[
              {
                required: true,
                message: "Please input how many guest !",
              },
            ]}
            >
              {getBranchsId===false?  
              (
                <Select>
                </Select>
                ) 
                : 
                (
                <Select>
                    {getBranchsId.map((element, i) => <Option key={i} value={element._id}>{element.areaName}</Option>)}
                </Select>
                )}


            </Form.Item>
          </Form.Item>
      
      </Form>
    </Modal>
        </div>
    )
}