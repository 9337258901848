import { Button, Form, Layout, Space } from "antd";
import { React, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import { show } from "./api";

const { Content } = Layout;

export default function CustomerShow({ token , dataWaitList}) {
  const [record, setRecord] = useState({
    status: "",
    hasCalled: "",
    _id: "",
    guest: {
        _id: "",
        name: "",
        mobile: ""
    },
    reservationType: "",
    guest_size: "",
    createdAt: "",
    company: "",
    __v: ""
  });

//   branch: "62521c04bced7f7185a380b1"
// company: "62521bdcbced7f7185a380a5"
// count: 0
// createdAt: "2022-05-16T10:42:33.918Z"
// guest: {_id: '62822a9924c5aeee5620d689', mobile: '0539878943', __v: 0, createdAt: '2022-05-16T10:42:33.805Z', name: 'ss', …}
// guest_size: 2
// hasCalled: false
// hasConfirmed: true
// reservationType: "outdoor"
// updatedAt: "2022-05-16T10:58:57.304Z"
// __v: 0
// _id: "62822a99bcf01eb3581d5ddd"
  const  navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if(dataWaitList){
      const WL = dataWaitList.filter((waitlist) => waitlist._id === id)
      setRecord(WL[0]);
    }
    
  }, [token,dataWaitList,id]);
  
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };

  return (
    <>
      <Content >
    <div id="page-titel" style={{margin: "10px"}}>
        <h1>Show Waitlist</h1>
      </div>
      <div id="content-add-wl" >
      <Form
        layout="vertical"
        autoComplete="off"
        style={{fontWeight: "bold"}}
      >
        <div>
          <div>
          <div className="style-show-waitlist">
        <h1 >Name: </h1>
        <p style={{fontWeight:"lighter"}}>{record.guest.name}</p>
        </div>
        <div className="style-show-waitlist">
        <h1 >Mobile: </h1>
        <p style={{fontWeight:"lighter"}}>{record.guest.mobile}</p>
        </div>
        </div>
        <div>
        <div className="style-show-waitlist">
        <h1 >Size: </h1>
        <p style={{fontWeight:"lighter"}}>{record.guest_size}</p>
        </div>
        <div className="style-show-waitlist">
        <h1 >Reservation Type: </h1>
        <p style={{fontWeight:"lighter"}}>{record.reservationType}</p>
        </div>
        </div>
        <div className="style-show-waitlist">
        <h1 >Reservation Date: </h1>
        <p style={{fontWeight:"lighter"}}>{new Date(record.createdAt).toLocaleString("en-US", options)}</p>
        </div>
        </div>
        <Form.Item>
          <Space>
          <Button id="btn-add-back-WL" style={{backgroundColor:"#000033"}} type="primary" onClick={() => navigate("/dashboard")}>
          Back
        </Button>
          </Space>
        </Form.Item>
      </Form>
      </div>
    </Content>

    </>
  );
}
