import React, { useState } from "react";
import {createEmployee, deleteEmployee} from "../../api";
import AddEmployees from "./AddEmployees"
import {
  Tag,
  Space,
  Table,
  Button
} from "antd";
import {
  UsergroupAddOutlined
} from "@ant-design/icons";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'


const MySwal = withReactContent(Swal)



export default function Employees({company,token,setCompany}) {
  const [visible, setVisible] = useState(false);

  const onClick = (id) => {

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        deleteEmployee(token,id)
        .then(() => {
          Swal.fire(
            'Deleted!',
            'Employeer has been deleted.',
            'success'
          )
          const newUsers = company.users.filter((user) => user._id !== id)
          company.users = newUsers
          setCompany({
            ...company
          })
        })
        .catch((error) => {
          console.log(error)
        })
      }
    })
}

  const columns = [
    {
      title: 'FullName',
      dataIndex: 'fullName',
      key: 'fullName',
      render: text => <a>{text}</a>
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      render: role => role.toUpperCase()
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Branchs',
      key: 'branches',
      dataIndex: 'branches',
      style:{color:"red"},
      render: branches => (
        <>
          {branches.map(branche => {
            let color = 'geekblue' ;
            return (
              <Tag color={color} key={branche._id} >
                {branche.areaName.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: '_id',
      render: _id => (
          <Space size="middle">
              <a  key={_id} onClick={() => onClick(_id)}>Delete </a>
          </Space>
      )
    },
  ];


  const onCreate = (values) => {
    setVisible(false);
    createEmployee(token,values)
    .then(() => {
      MySwal.fire({
              title: <strong>Success</strong>,
              html: <i>Employeer Added Success!</i>,
              icon: 'success'
      })
    })
    .catch((error) => {
       Swal.fire({
        icon: 'error',
        title: "Oops..Can't add employeer!",
        text: error.response.data.error,
        footer: '<a href="https://hjz.sa/">Why do I have this issue? Contact hjz Team</a>'
        })
    })
   
  };

  return(
      <div >
        <div>
          <Button
            type="primary"
            style={{backgroundColor:"#000032",borderColor:"#000032", marginBottom:"2%" }}
            onClick={() => {
              setVisible(true);
            }}
          >
            <UsergroupAddOutlined/> Add Employee
          </Button>
          <AddEmployees
            visible={visible}
            onCreate={onCreate}
            company={company}
            onCancel={() => {
              setVisible(false);
            }}
          />
      </div>
      <Table className="branches" columns={columns} dataSource={company.users}  />
      </div>
  )

}