import React, {useEffect, useState} from "react";
import {getBranchs} from "../../api";
import {Table} from "antd";


export default function Branchs(token) {
    const [getBranchsData, setBranchsData] = useState([]);
    useEffect(()=>{
        getBranchs(token.token)
        .then((res)=>{
          // console.log(res.data);
          setBranchsData(res.data)
        }).catch((err)=>{
          console.log(err);
        }
        )
    },[token]);

    const dataSource = getBranchsData.map(branche => {
      return (
        {
          key: branche._id,
          city:branche.city,
          areaName:branche.areaName ,
          createdAt:branche.createdAt
        })
    });
    // console.log(getBranchsData);
    const columns = [
        {
          title: 'City',
          dataIndex: 'city',
          key: 'city',
          render: text => <a>{text}</a>
        },
        {
          title: 'AreaName',
          dataIndex: 'areaName',
          key: 'areaName',
          render: areaName => areaName.toUpperCase()
        },
        {
          title: 'CreatedAt',
          dataIndex: 'createdAt',
          key: 'createdAt',
        },
        {
          title: 'Action',
          key: 'action',
          dataIndex: '_id',
          render: _id => ( 
            <a>Delete </a>
          ),
        },
      ];
      return(
          <div >
          <Table className="branches" dataSource={dataSource} columns={columns}  />
          </div>
      )
}
