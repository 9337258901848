import axios from "axios";
//http://ec2-34-246-182-227.eu-west-1.compute.amazonaws.com:3000
//http://localhost:4001
export const url = "https://app.hjz.sa";

const headers = {
  "Access-Control-Allow-Origin": url,
};



//////////// connection the client with the server API ////////////

export const validateToken = async (token) => {};

//////////// REGISTER ////////////
// api 'post' Register  company   ** /api/company **
export const register = (body) => {
  return axios
    .post(
      url + "/api/company",
      body
    )
};

//////////// COMPANY ////////////
export const getCompany = async (token) => {
  headers["x-auth-token"] = token;
  return axios.get(url + "/api/company/", { headers: headers });
};


// PUT 
export const updateCompany = async (token, body) => {
  headers["x-auth-token"] = token;
  return axios.put(url + "/api/company/", body ,{ headers: headers });
};



//////////// GET - BRANCH ////////////
export const getBranch = async (token,id) => {
  headers["x-auth-token"] = token;
  return axios.get(url + `/api/company/branch/${id}`, { headers: headers });
};

//////////// CREATE - BRANCH ////////////
export const createBranch = async (token, body) => {
  headers["x-auth-token"] = token;
  return axios.post(url + `/api/company/branch`, body ,{ headers: headers });
};


//////////// PUT - BRANCH ////////////
export const updateBranch = async (token, body, branch_id) => {
  headers["x-auth-token"] = token;
  return axios.put(url + `/api/company/branch/${branch_id}`, body ,{ headers: headers });
};

//////////// GET - BRANCHs ////////////
export const getBranchs = async (token) => {
  headers["x-auth-token"] = token;
  return axios.get(url + `/api/company/branch/`, { headers: headers });
};

//////////// GET - ALL WAITLISTS ////////////
export const allWitlist = (token,branch_id) => {
  headers["x-auth-token"] = token;
  return axios.get(url + `/api/company/branch/${branch_id}/waitlist`, { headers: headers });
};


//////////// UPDATE -  WAITLIST ////////////
export const updateWaitlist = (waitlist, item, token,branch_id) => {
  headers["x-auth-token"] = token;
  return axios.put(
    url + `/api/company/branch/${branch_id}/waitlist/${item.key}`,
      waitlist
    ,
    {
      headers: headers,
    }
  );
};


//////////// CREATE - WAITLIST ////////////
export const addGuestToWaitlist = (branchId, token, body) => {
  headers["x-auth-token"] = token;
  return axios.post(url + `/api/company/branch/${branchId}/waitlist`, body, {headers: headers,});
};

//////////// GET REPORT FOR ALL CURRENT WAITLIST ////////////
// api 'get' all witlist ** api/witlist/ **
export const getReports = (token,branch_id) => {
  return allWitlist(token,branch_id).then((res) => {
    const report = res.data.reduce(
      (acc, current) => {
        
        if (current.status === "served") {
          acc.totalServed = acc.totalServed + current.guest_size;
          acc.total = acc.total + current.guest_size;
        }
        if (current.status === "canceled") {
          acc.totalCanceled = acc.totalCanceled + current.guest_size;
          acc.total = acc.total + current.guest_size;
        }
        if (current.status === "waiting") {
          acc.totalWaiting = acc.totalWaiting + current.guest_size;
          acc.total = acc.total + current.guest_size;
        }
        if (current.reservationType === "any") {
          acc.totalServedArea.any++;
        }
        if (current.reservationType === "indoor") {
          acc.totalServedArea.indoor++;
        }
        if (current.reservationType === "outdoor") {
          acc.totalServedArea.outdoor++;
        }
        acc.avg = acc.avg + current.guest_size;
        acc.totalWaitlist += 1
        return acc;
      },
      {
        avg: 0,
        total: 0,
        totalWaitlist: 0,
        totalServed: 0,
        totalCanceled: 0,
        totalWaiting: 0,
        totalServedArea: {
          any: 0,
          indoor: 0,
          outdoor: 0,
        },
      }
    );
    report.avg = report.avg / res.data.length;
    return report;
  });
};
//////////// CREATE - SECTION ////////////
export const createSection = (token, branch_id, body) => {
  headers["x-auth-token"] = token;
  return axios.post(url + `/api/company/branch/${branch_id}/section`, body, {headers: headers});
};
//////////// DELETE - SECTION ////////////
export const deleteSection = (token, branch_id, section_id ) => {
  headers["x-auth-token"] = token;
  return axios.delete(url + `/api/company/branch/${branch_id}/section/${section_id}`, {headers: headers});
};
//////////// GET - ALL SECTIONS ////////////
export const getSections = async (token, branch_id) => {
  headers["x-auth-token"] = token;
  return axios.get(url + `/api/company/branch/${branch_id}/section`, { headers: headers });
};

////////// GET - ALL Tables ////////////
export const getTables = async (token, branch_id, section_id) => {
  headers["x-auth-token"] = token;
  return axios.get(url + `/api/company/branch/${branch_id}/section/${section_id}/table`, { headers: headers });
};

//////////// CREATE - EMPLOYEER ////////////
export const createEmployee = (token, body) => {
  headers["x-auth-token"] = token;
  return axios.post(url + `/api/user/new-employee`, body, {headers: headers});
};


//////////// DELETE - EMPLOYEER ////////////
export const deleteEmployee = (token, branch_id) => {
  headers["x-auth-token"] = token;
  return axios.delete(url + `/api/user/${branch_id}`, {headers: headers});
};

//////////// GET - ALL CUSTOMERS ////////////
export const allCustomer = (token,branch_id) => {
  headers["x-auth-token"] = token;
  return axios.get(url + `/api/company/branch/${branch_id}/guest`, { headers: headers });
};


//////////// POST - SEND A MESSAGE TO GUEST SMS  ////////////
export const sendMessage = (branchId, waitlistKey,body, token) => {
  headers["x-auth-token"] = token;
  return axios
    .post(url + `/api/company/branch/${branchId}/waitlist/${waitlistKey}/notify`, body, { headers: headers });
};









// api 'post' before complete profile company   ** /api/company/profile **
// export const completeProfile = (body, token) => {
//   headers["X-Auth-Token"] = token;
//   return axios
//     .post(url + "/api/company/profile", body, { headers: headers });
// };


// api 'put' update profile company   ** /api/company/profile **
export const updateProfile = (body, token) => {
  headers["X-Auth-Token"] = token;
  axios
    .put(url + "/api/company/profile", body, { headers: headers })
    .then()
    .catch((err) => {
    });
     
};




// api 'get' show one person in witlist  ** /api/witlist/:WL-id/show **
export const show = (item, token) => {
  headers["X-Auth-Token"] = token;
  return axios.get(url + "/api/waitlist/" + item + "/show", {
    headers: headers,
  });
};




// api 'put' update witlist    ** /api/waitlist_id/"calling Or served" **
export const changeState = (waitlist, item, token) => {
  headers["X-Auth-Token"] = token;
  return axios.put(
    url + "/api/waitlist/" + item.key + "/status",
    {
      waitlist: waitlist,
    },
    {
      headers: headers,
    }
  );
};

// api 'put' update witlist    ** /api/waitlist_id/"calling Or served" **
// export const changeState = (waitlist, item, token) => {
//   headers["X-Auth-Token"] = token;
//   return axios.put(
//     url + "/api/waitlist/" + item.key + "/status",
//     {
//       waitlist: waitlist,
//     },
//     {
//       headers: headers,
//     }
//   );
// };

// ******************************************************************

// ********************************************************************
// add reservation mauale

// api 'post' send gest confirmed to back-end from us manul  ** /api/witlist/:company-id/confirmed **
// state here 'witing' becouse it do from clint


//   export const signIn = credentials => {
//     return axios({
//       url: apiUrl + '/sign-in',
//       method: 'POST',
//       data: {
//         credentials: {
//           email: credentials.email,
//           password: credentials.password
//         }
//       }
//     })
//   }



export const exportToCSV = (token) => {
  function convertToCSV(objArray) {
    var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    var str = "";

    for (var i = 0; i < array.length; i++) {
      var line = "";
      for (var index in array[i]) {
        if (line !== "") line += ",";

        line += array[i][index];
      }

      str += line + "\r\n";
    }
    return str;
  }

  function exportCSVFile(headers, items, fileTitle) {
    if (headers) {
      items.unshift(headers);
    }

    // Convert Object to JSON
    var jsonObject = JSON.stringify(items);

    var csv = convertToCSV(jsonObject);

    var exportedFilenmae = fileTitle + ".csv" || "export.csv";

    var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
      var link = document.createElement("a");
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportedFilenmae);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  const headersCSV = {
    name: "Name".replace(/,/g, ""), // remove commas to avoid errors
    phone: "Phone Number",
    lastVisit: "Last Visit",
  };
  var options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZone: "UTC",
  };
  allCustomer(token).then((res) => {
    const itemsFormatted = [];
    res.data.forEach((item) => {
      itemsFormatted.push({
        name: item.guest.name.replace(/,/g, ""), // remove commas to avoid errors,
        phone: "'" + item.guest.mobile,
        lastVisit: Date(item.date).toLocaleString("en-US", options),
      });
    });
    
    let fileTitle = "exportCsv" + new Date().toLocaleString();
    exportCSVFile(headersCSV, itemsFormatted, fileTitle); // call the exportCSVFile() function to process the JSON and trigger the download
  });
};


// close Application api/application


export const closeApp = (body, token) => {
  headers["X-Auth-Token"] = token;
  return axios
    .post(url + "/api/application", body, { headers: headers });
};




// export const changeState = (waitlist, item, token) => {
//   headers["X-Auth-Token"] = token;
//   return axios.put(
//     url + "/api/waitlist/" + item.key + "/status",
//     {
//       waitlist: waitlist,
//     },
//     {
//       headers: headers,
//     }
//   );
// };