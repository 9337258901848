import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import ApexCharts from "apexcharts";
import { getReports } from "./../api";

const { Content } = Layout;
export default function Report({ token, branch}) {
  const [reportData, setReportData] = useState({
    avg: 0,
    total: 0,
    totalWaitlist: 0,
    totalServed: 0,
    totalCanceled: 0,
    totalWaiting: 0,
    totalServedArea: {
      any: 0,
      indoor: 0,
      outdoor: 0,
    },
  });
  var chart;
  useEffect(() => {
    try{
      getReports(token,branch._id).then((res) => {
       console.log(res)
        setReportData(res);
        var options = {
          series: [
            res.totalServedArea.any,
            res.totalServedArea.indoor,
            res.totalServedArea.outdoor,
          ],
          chart: {
            type: "donut",
          },
          labels: ["Any","Indoor", "Outdoor"],
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        };
  
        chart = new ApexCharts(document.querySelector("#chart"), options);
        if(chart)
          chart.render();
      });
    }catch(e){

    }
  }, [branch]);
  return (
    <>
    <Content className="sub-component main-reports">
      <div id="page-titel">
        <p>Report</p>
      </div>
      <div className="body-reports">
      <div className="sub-main-reports">
        <div className="box-reports">

          <div
          id="box-Average-Guests-size">
            <div id="avgGestStyle">
              {reportData.avg ? parseFloat(reportData.avg).toFixed() : 0}
            </div>
            <h1 className="title-avg-guests">
              Average Guests size
            </h1>
          </div>

          <div className="sub-box-reports">
          <div
            id="box-Total-Number-Of-Guests-Visits">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {" "}
              <h1
                style={{
                  color: "#000033",
                  fontSize: "3em",
                  fontWeight: "bold",
                }}
              >
                {reportData.total}
              </h1>{" "}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h1
                style={{
                  color: "#096dd9",
                  fontSize: "medium",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Total Number Of Guests Visits
              </h1>
            </div>
          </div>
          </div>

          <div className="sub-box-reports">
          <div
            id="box-Total-Number-Of-People-Served">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {" "}
              <h1
                style={{
                  color: "#000033",
                  fontSize: "3em",
                  fontWeight: "bold",
                }}
              >
                {reportData.totalServed}
              </h1>{" "}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {" "}
              <h1
                style={{
                  color: "#90C7AF",
                  fontSize: "medium",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Total Number Of People Served
              </h1>{" "}
            </div>
          </div>
          <div
            id="box-Total-Number-of-People-Canceled">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {" "}
              <h1
                style={{
                  color: "#000033",
                  fontSize: "3em",
                  fontWeight: "bold",
                }}
              >
                {reportData.totalCanceled}
              </h1>{" "}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {" "}
              <h1
                style={{
                  color: "#DD6E8B",
                  fontSize: "medium",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Total Number of People Canceled
              </h1>{" "}
            </div>
          </div>
          <div
            id="box-Total-Number-Of-People-Waiting">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {" "}
              <h1
                style={{
                  color: "#000033",
                  fontSize: "3em",
                  fontWeight: "bold",
                }}
              >
                {reportData.totalWaitlist}
              </h1>{" "}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {" "}
              <h1
                style={{
                  color: "rgb(237, 208, 150)",
                  fontSize: "medium",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                TOTAL BOOKING
              </h1>{" "}
            </div>
          </div>
        </div>
        </div>
      </div>

      <div className="chart-reports">
        <div className="reports-chart">
          <h1>SERVED AREA TYPE</h1>
          <div id="chart"></div>
        </div>
      </div>
      </div>

      
        
      </Content>

    </>
  );
}
