import {
  DeleteOutlined,
  PhoneOutlined,
  EditOutlined,
  SearchOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import {
  Table,
  Input,
  Select,
  InputNumber,
  Popconfirm,
  Form,
  Typography,
  Button,
  Space,
  Tag,
  message,
  Rate,
} from "antd";

import React, { useEffect, useState,useRef } from "react";
import Highlighter from "react-highlight-words";
import { useNavigate } from "react-router-dom";
import {updateWaitlist, sendMessage, allWitlist} from "./api";
import useWindowDimensions from "./sub-routes-components/useWindowDimensions"
// import {socket} from '../App';
// import { parseJwt } from "./commonFunctions";
import Swal from 'sweetalert2'
import useAuth from './auth/useAuth'

let timerInterval
const { Option } = Select;
var profileData = {
  maxGuest:[],
  reservationType:[]
}
const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  let inputNode = '' 
  
switch(inputType) {
  case "number":
    inputNode = <InputNumber />
    break;
  case 'select':
    if(dataIndex === "Area"){
      inputNode =  <Select>
      {profileData.reservationType.map((element, i) => <Option key={i} value={element}>{element}</Option>)}
    </Select>
    }else if (dataIndex === "Guests"){
      
      inputNode =  <Select>
          {profileData.maxGuest.map((element, i) => <Option key={i} value={element}>{element}</Option>)}
      </Select>
    }
    else if (dataIndex === "sectionType"){
      inputNode =  <Select>
      {profileData.sectionType ? profileData.sectionType.map((element, i) => <Option key={i} value={element}>{element}</Option>):""}
    </Select>
    }
    else if (dataIndex === "Status"){
      
      inputNode =  <Select>
          <Option value="waiting">waiting</Option>
          <Option value="served">served</Option>
          <Option value="canceled">canceled</Option>

      </Select>
    }
  
    
    break;
  default:
    inputNode =  <Input />
}
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};


// update Table ********
const EditableTable = ({ _filter,branch,company, token, setWaitlist, headSearch , dataWaitList, setDataWaitList}) => {
  const [form] = Form.useForm();
  const [data, setData] = useState(null);
  const [editingKey, setEditingKey] = useState("");
  const [filterdData, setfilterdData] = useState([]);
  // const [responseSocket, setResponseSocket] = useState(null);
  const wList = useRef({ current: []})
  const [getTableHiehgt, setTableHiehgt] = useState({ y: 500})
  const [visible, setVisible] = useState(false);
  const [hasManySection, setHasManySection] = useState(false);
  const [sendWithTableNumber, setSendWithTableNumber] = useState(null)  
  const [isLoading, setLoading] = useState(false)
  const tableNumberBody =(number) =>{
   return {
     "tableNumber": number
    }
  }

  useEffect(() => {
    try {
      if(branch.hasManySection){
        setHasManySection(branch.hasManySection)
      }
      (profileData.maxGuest = [ ...Array(branch.maxGuestPerTable+1).keys() ]).shift()
      profileData.reservationType = branch.reservationTypes
      setSendWithTableNumber(branch.sendWithTableNumber)
      if(branch.hasManySection){
        profileData.sectionType = ['family','family_partition','single']
      }
    } catch (error) {
      // message.warn("Update Failed!");
      setLoading(false);
    }

  },[branch]);


  const sendWithTableNumberPrompt = (waitlistKey)=>{ 
    setLoading(true);
    Swal.fire({
      title: 'Enter table number',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Send',
      confirmButtonColor:'#000033',
      showLoaderOnConfirm: true,
      inputValidator: (value) => {
        return new Promise((resolve) => {
          let reg = new RegExp("^([\\d]{1,3})$")
          
          const number = reg.exec(value) // parseInt(value)

          if (number !=null && number[0]>0 && number[0]<=999) {
            resolve()
          } else {
            resolve('You can only enter a 3-digit number :)')
          }
        })
      },
      allowOutsideClick: () => Swal.isLoading()
    }).then((result) => {
      setLoading(false);
      if (result.isConfirmed) {
        setLoading(true);
        if(smsStatus === true){
          Swal.fire({
            title: 'Send SMS!',
            html: 'Sending...',
            timer: 1000,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading()
            },
            willClose: () => {
              clearInterval(timerInterval)
              setLoading(false);
            }
          })
          const newData = data.map(rec => {
            if(rec.key===waitlistKey) {
              rec.Status="coming"
            }  
            return rec;
          });
          updateStatus(waitlistKey, token)
          .then(()=>{
            sendMessage(branch._id,waitlistKey,tableNumberBody(result.value), token)
            .then((res)=>{
              if(res.status === 203){
                setSmsStatus(false);
              }
            }) 
            .catch((err)=>{
              message.warn("SMS Send Failed!");
              setLoading(false);
            })
          })
          .catch(()=>{
            const newData = data.map(rec => {
              if(rec.key===waitlistKey) {
                rec.Status="waiting"
              }  
              return rec;
            });
            setData(newData);
            setWaitlist(newData);
            message.error('Internet Failed - Reconnecting');
          }) 
        }else{
          const newData = data.map(rec => {
            if(rec.key===waitlistKey) {
              rec.Status="coming"
            }  
            return rec;
          });
          updateStatus(waitlistKey, token)
          .then(()=>{
          })
          .catch(()=>{
            const newData = data.map(rec => {
              if(rec.key===waitlistKey) {
                rec.Status="waiting"
              }  
              return rec;
            });
            setData(newData);
            setWaitlist(newData);
            message.error('Internet Failed - Reconnecting');
          }) 
        }
      }
    })
    setLoading(false);
  }

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {

    let objField = {
      name: "",
      Phone: "",
      Area: "",
      ...record,
    }

    if(hasManySection){
      objField.section = ""
    }

    form.setFieldsValue(objField);
    setEditingKey(record.key);
  };


  const cancel = () => {
    setEditingKey("");
  };
  

  const save = async (key) => {
    setLoading(true);
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        const newItem = newData[index];
        const waitlist = {
          waitlist:{
              reservationType: newItem.Area,
              guest_size: newItem.Guests,
              status:newItem.Status,
          },
          guest: {
            name: newItem.name,
            mobile: newItem.Phone,
          }
        };
        if(hasManySection){
          waitlist.waitlist.sectionType = newItem.sectionType
        }
        setLoading(true);
        updateWaitlist(waitlist, item, token,branch._id)
        .then((res) =>{
          message.success("Update Success!");
          setWaitlist(newData);
          setData(newData);
          setLoading(false);
        })
        .catch((err)=>{
          setLoading(false);
          message.warn("Update Failed!" + `(${err.response.data.error})`);
          message.error('Internet Failed - Reconnecting');
        }) 
        setEditingKey("");
      }
    } catch (errInfo) {
      message.warn("Update Failed! - (save)");
      setLoading(false);
    }
  };

  // end update *****
  // update status
  const updateStatus =  (key) => {
    return new Promise(async(resolve, reject) => {
      try {
        setLoading(true);
        const row =  await form.validateFields();
        const newData = [...data];
        const index = newData.findIndex((item) => key === item.key);
        if (index > -1) {
          const item = newData[index];
          newData.splice(index, 1, { ...item, ...row });
          const newItem = newData[index];
          const waitlist = {
            waitlist:{
                reservationType: newItem.Area,
                guest_size: newItem.Guests,
                status:newItem.Status,
            },
            guest: {
              name: newItem.name,
              mobile: newItem.Phone,
            }
          };
          if(branch.hasManySection){
            waitlist.waitlist.sectionType = newItem.sectionType
          }
  
          updateWaitlist(waitlist, item, token,branch._id)
          .then((res) =>{
            message.success("Update Success!");
            setWaitlist(newData);
            setData(newData);
            resolve("SUCCESS")
            setLoading(false);
          })
          .catch((err)=>{
            setWaitlist(data);
            setData(data);
            message.warn("Update Failed! - (Try again) ");
            setLoading(false);
            reject("FAILURE")
          }) 
          setEditingKey("");
        }
      } catch (errInfo) {
        message.warn("Update Failed! catch at try");
        setLoading(false);
        reject("FAILURE")
      }
    })
 
  };
  // end update status


  // start update status to canceled
  const updateStatusToCanceled = (record) => {
    return new Promise((resolve, reject) => {
      try{
        setLoading(true);
        const newData = [...data];
        const index = newData.findIndex((item) => record.key === item.key);
        if (index > -1) {
          // const item = newData[index];
          newData[index].status = "canceled"
          newData.splice(index, 1, newData[index]);
          const newItem = newData[index];
  
          const waitlist = {
            waitlist:{
                reservationType: newItem.Area,
                guest_size: newItem.Guests,
                status:"canceled",
            },
            guest: {
              name: newItem.name,
              mobile: newItem.Phone,
            }
          };
          if(branch.hasManySection){
            waitlist.waitlist.sectionType = newItem.sectionType
          }
          updateWaitlist(waitlist, record, token,branch._id)
            .then((res) =>{
              setLoading(false);
              setWaitlist(newData);
              setData(newData);
              message.success("Update Success!");
              resolve("SUCCESS")
            })
            .catch((err)=>{
              message.warn("Update Failed!" + `(${err.response.data.error})`);
              setLoading(false);
              reject("FAILURE")
          }) 
        }
      }catch{
        message.warn("Cancaled Failed!");
        setLoading(false);
        reject("FAILURE")
      }
    });
  };



  // end update status to canceled


  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    editSearchText(selectedKeys[0]);
    editSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    editSearchText("");
  };

  const [searchText, editSearchText] = useState("");
  const [searchedColumn, editSearchedColumn] = useState(0);

  let searchInput;
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const {smsStatus,setSmsStatus} = useAuth();
  const navigate = useNavigate();
  const columns = [
    {
      title: "",
      dataIndex: "Visits",
      key: "Visits",
      editable: false,
      align: "center",
      sorter: (a, b) => a.Visits - b.Visits,
      width: '8%',
      render: (Visits) => {
        return (
          <div className="stars">
              <Rate disabled allowHalf defaultValue={Visits/2} count={5} />
          </div>
        );
      }
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <p style={{color:"#1890FF"}} onClick={() => navigate("./CustomerShow/" + record.key)}>
          {text}
        </p>
      ),
      editable: false,
      sorter: (a, b) => a.name.length - b.name.length,
      align: "center",
    },
    {
      title: "Phone",
      dataIndex: "Phone",
      key: "Phone",
      editable: true,
      align: "center",
      ...getColumnSearchProps("Phone"),
      filteredValue:[headSearch.toString()] || null
      
    },
    {
      title: "Area",
      key: "Area",
      dataIndex: "Area",
      editable: true,
      filters: branch.reservationTypes ? branch.reservationTypes.map(element => ({text:element,value:element})): [],
      filteredValue: filterdData.Area || null ,
      onFilter: (value, record) => record.Area.includes(value),
      align: "center",
       width: '12%',
    },
    {
      title: "Guests",
      dataIndex: "Guests",
      key: "Guests",
      editable: true,
      align: "center",
      width: '10%',
      // ...getColumnSearchProps("Guests"),
      sorter: (a, b) => a.Guests - b.Guests,
    },
    {
      title: "Status",
      key: "Status",
      dataIndex: "Status",
      editable: true,
      filteredValue: _filter,
      onFilter: (value, record) =>
        record.Status.toLowerCase() === value.toLowerCase(),
      align: "center",
      render: (status) => {
          let color = "grey";
          if (status === "canceled") {
            color = "red";
          }
          if (status === "served") {
            color = "green";
          }
          if (status === "waiting") {
            color = "yellow";
          }
          if (status === "coming") {
            color = "darkorange";
          }
          return (
            <span>
              <Tag color={color} key={status}>
                {status.toUpperCase()}
              </Tag>
            </span>
          );
      },
    },
    {
      align: "center",
      // title: "Actions",
      // dataIndex: "operation",
      title: "Actions",
      dataIndex: "Actions",
      key: "Actions",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <a
              href="javascript:;"
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </a>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <Typography.Link
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
            >
              {(record.Status==="coming")? <EditOutlined style={{marginRight:"21px"}} />: (record.Status==="served"||record.Status==="canceled")?<EditOutlined style={{marginRight:"80px"}}/>:<EditOutlined style={{marginLeft:"-2px"}}/>}
            </Typography.Link>

            {/* ||record.Status=="served"||record.Status=="canceled" */}
            <Typography.Link
              disabled={editingKey !== ""}
              style={{ marginLeft: "14px" }}
              onClick={() => {
                // const newData = data.map(rec => {
                //   if(rec.key===record.key) {
                //     rec.Status="coming"
                //   }  
                //   return rec;
                // });
                // setData(newData)
                // setWaitlist(data)

                // .catch(()=>{message.warn("Submit Failed!");})
                // .then(()=>{message.success("Submit Success!");}) 
                // // changeState(record.key, comingStatus, token)
              
                // onClickVisible()
              }}
            > 
            {(record.Status==="waiting")? <PhoneOutlined style={{ color: "green",marginLeft: "25px",marginRight:"16px" }} onClick= {
              function(){
                setVisible(true);
                if(sendWithTableNumber){
                  sendWithTableNumberPrompt(record.key);
                  setLoading(false);
                }else{
                  if(smsStatus){
                    setLoading(true);
                    Swal.fire({
                      title: 'Send SMS!',
                      html: 'Sending...',
                      timer: 1000,
                      timerProgressBar: true,
                      didOpen: () => {
                        Swal.showLoading()
                      },
                      willClose: () => {
                        clearInterval(timerInterval);
                      }
                    })
                    const newData = data.map(rec => {
                      if(rec.key===record.key) {
                        rec.Status="coming"
                      }  
                      return rec;
                    });    
                    updateStatus(record.key, token)
                    .then(()=>{                       
                        sendMessage(branch._id,record.key,null, token)
                        .then((res)=>{

                        }) 
                        .catch((err)=>{
                          if(err.response.status === 400){
                              setSmsStatus(false);
                              setLoading(false);
                              Swal.fire({
                                icon: 'error',
                                title: "Oops..You don't have SMS.",
                                text: err.response.data.error,
                                footer: '<a href="https://hjz.sa/">You need to recharege the SMS. Contact hjz Team</a>'
                                })
                          }else {
                            message.warn("SMS Send Failed!");
                            setLoading(false);
                          }
                        })  
                    })
                    .catch(()=>{
                      const newData = data.map(rec => {
                        if(rec.key===record.key) {
                          rec.Status="waiting"
                        }  
                        return rec;
                      });  
                      setData(newData);
                      setWaitlist(newData);
                      setLoading(false);
                      message.error('Internet Failed - Reconnecting');
                    })

                  }else{       
                      const newData = data.map(rec => {
                        if(rec.key===record.key) {
                          rec.Status="coming"
                        }  
                        return rec;
                      });    
                      updateStatus(record.key, token)
                      .then(()=>{
                      })
                      .catch(()=>{
                        const newData = data.map(rec => {
                          if(rec.key===record.key) {
                            rec.Status="waiting"
                          }  
                          return rec;
                        });  
                        setData(newData);
                        setWaitlist(newData);
                        message.error('Internet Failed - Reconnecting');
                      })
                  }
                }
              }
            }/>: null}
            </Typography.Link>

            <Typography.Link
             disabled={editingKey !== ""}
             onClick={() => {
              const newData = data.map(rec => {
                if(rec.key===record.key) {
                  rec.Status="served"
                }  
                return rec;
              });
              updateStatus(record.key, token)
              .then(()=>{
              })
              .catch(()=>{
                const newData = data.map(rec => {
                  if(rec.key===record.key) {
                    rec.Status="coming"
                  }  
                  return rec;
                });
                setData(newData);
                setWaitlist(newData);
                message.error('Internet Failed - Reconnecting');
              }) 
             }}
              style={{ color: "green", marginRight: "20px" }}
            >
            {(record.Status==="coming")? <CheckOutlined style={{marginRight:"15px"}}/>: null}
            </Typography.Link>

            <Typography.Link
              disabled={editingKey !== ""}
              onClick={() => {
                updateStatusToCanceled(record)
                .then(()=>{
                  const newData = data.map(rec => {
                    if(rec.key===record.key) {
                      rec.Status="canceled"
                    }  
                    return rec;
                  });
                  setData(newData);
                  setWaitlist(newData);
                })
                .catch(()=>{
                  message.error('Internet Failed - Reconnecting');
                })
              }}
            >
              {(record.Status==="waiting")? <DeleteOutlined style={{ color: "red" }} />: null}
              {(record.Status==="coming")? <CloseOutlined style={{ color: "red" }} />: null}
            </Typography.Link>
            
          </div>
        );
      },
    },
  ];
  
  if(hasManySection){
    columns.splice(2,0,
      {
        title: "Section",
        key: "sectionType",
        dataIndex: "sectionType",
        editable: true,
        filters: [
          {
            text: "family",
            value: "family",
          },
          {
            text: "family_partition",
            value: "family_partition",
          },
          {
            text: "single",
            value: "single",
          }
        ],
        filteredValue: filterdData.sectionType || null ,
        onFilter: (value, record) => typeof(record.sectionType) === "string" ? record.sectionType.includes(value):false,
        align: "center",
      }
    )
  }

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "Phone" ? "input" : "select",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

 
// GET all waitlist API and set them on setWaitlist, setData variables
  useEffect(() => {
    setLoading(true);
    if(branch){
      allWitlist(token,branch._id)
      .then((res) => {
        setLoading(false)
        var arr = [];
        setDataWaitList(res.data.reverse())
        res.data.forEach((waitlist) => {
          const objWaitlist = {
            key: waitlist._id,
            name: waitlist.guest.name,
            Phone: waitlist.guest.mobile,
            Area: waitlist.reservationType,
            Guests: waitlist.guest_size,
            Status: waitlist.status,
            Visits: waitlist.count,
            sectionType : waitlist.sectionType === 'nither' ? '' : waitlist.sectionType
          }
          arr.push(objWaitlist);
        });
        setWaitlist(arr);
        setData(arr);
        wList.current = arr
      })
       .catch(err => console.log(err))
    }
    return () => {};
  }, [branch,company]);



const {socket} = useAuth();


useEffect( () => {
    if(socket != null ){
      try{
        socket.on("subscribe",  resData => {          
        const newWaitlist = {
              key: resData._id,
              name: resData.guest.name,
              Phone: resData.guest.mobile,
              Area: resData.reservationType,
              Guests: resData.guest_size,
              Status: resData.status,
              Visits: resData.count,
              sectionType : resData.sectionType == 'nither' ? '' : resData.sectionType
          }
        setData(previousData => [...previousData,newWaitlist])
        wList.current.push(newWaitlist)
        setWaitlist(wList.current)
      });
      }catch{
        window.location.reload();
      }
  }
}, [socket]);

  function handelOnChange(pagination, filters) {
    setfilterdData(filters)
  }


  useEffect(() => {
    const hightScreen = window.innerHeight;
    const header = document.getElementById("header-component");
    const subHeader = document.getElementsByClassName("body-waitlist")[0].firstElementChild.clientHeight

    const tableHight = `calc(78vh - ${header.clientHeight + subHeader}px)`
    setTableHiehgt({y:tableHight})
  },[window.innerHeight])

  const { height, width } = useWindowDimensions();
  return (
    <Form form={form} component={false} className="waitlist-table" style={{ minHeight: "100vh" }}>
      <Table className="table waitlist-table"
        style={{ minHeight: "100vh" }}
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        // bordered
        loading={isLoading}
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        size="large"
        onChange={handelOnChange}
        pagination={true}
        scroll={getTableHiehgt}
      />
    </Form>
  );
};

export default EditableTable;
