import {useState,React,useEffect} from "react";
import {
  Form,
  Input,
  message,
  Select,
  Radio,
  Layout,
  Modal
} from "antd";

// import { useNavigate } from "react-router-dom";
// import { addGuestToWaitlist } from "./api";
// import useAuth from './auth/useAuth'
const { Content } = Layout;

const AddWL = ({onCancel,onCreateWaitlist,setAddGuestToWaitlist,getAddGuestToWaitlist,branch,token}) => {
  const [any, setAny] = useState(false);
  const [indoor, setIndoor] = useState(false);
  const [outdoor, setOutdoor] = useState(false);
  const [view, setView] = useState(false);
  const [getGuestSize, SetGuestSize] = useState(false);
  const [hasManySection, setHasManySection] = useState(false);

  // const [sectionInfo, setSectionInfo] = useState(false);
  // const {socket} = useAuth();

  const [form] = Form.useForm();

  useEffect(() => {
    
    try {
      if(branch.hasManySection){
        setHasManySection(branch.hasManySection)
      }
      SetGuestSize(branch.maxGuestPerTable)
      branch.reservationTypes.forEach((item) => {
        if (item === "any") {
          setAny(true);
        }
        if (item === "indoor") {
          setIndoor(true);
        }
        if (item === "outdoor") {
          setOutdoor(true);
        }
        if (item === "view") {
          setView(true);
        }
      });

      
    } catch (error) {
      
    }

    return () => {};
  }, [branch]);

  
  // const navigate = useNavigate();
 
  const { Option } = Select;

  const onFinish = (values) => {
    // const body = {
    //   waitlist:{
    //           reservationType: values.reservationType,
    //           guest_size: values.size,
    //   },
    //   guest: {
    //     name: values.name,
    //     mobile:  "05" + values.mobile,
    //   }
    // };

    // console.log(body,branch)
    // addGuestToWaitlist(branch._id, token, body)
    //   .then(() => {
    //     message.success("Created Success!");
    //     navigate("/dashboard");
    //   })
    //   .catch(() => {
    //     message.warn("Created Failed!");
    //     navigate("/dashboard");
    //   });
  };

  const onFinishFailed = () => {
    message.error("Submit failed!");
  };

  // const prefixSelector = (
  //   <Form.Item name="prefix" noStyle>
  //     <Select style={{ width: 70 }}>
  //       <Option value="05">05</Option>
  //     </Select>
  //   </Form.Item>
  // );

  // const formItemLayout = {
  //   labelCol: { span: 7 },
  //   wrapperCol: { span: 12 },
  // };
  Array.range = function(a, b, step){
      var A = [];
      if(typeof a == 'number'){
          A[0] = a;
          step = step || 1;
          while(a+step <= b){
              A[A.length]= a+= step;
          }
      }
      else {
          var s = 'abcdefghijklmnopqrstuvwxyz';
          if(a === a.toUpperCase()){
              b = b.toUpperCase();
              s = s.toUpperCase();
          }
          s = s.substring(s.indexOf(a), s.indexOf(b)+ 1);
          A = s.split('');        
      }
      return A;
  }

  return (
    <Modal
      visible={getAddGuestToWaitlist}
      title="New Waitlist"
      okText="Create"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreateWaitlist(values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
      >
    <Content className="addGuest">
    <div id="page-titel" className="sub-container ant-tabs-content-left container-form">
        {/* <h4 className="title-name-of-setting-menu">New Waitlist</h4> */}
      </div>
      <div  className="margin-auto" >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className="create-waitlist"
        initialValues={{
          modifier: 'public',
        }}
      >
        <div
          style={{
            overflow: "hidden",
          }}
        >
          {/* <h1>Area</h1> */}
          <Form.Item
            name="reservationType"
            label="Area"
            rules={[
              {
                required: true,
                message: "Please Choose Reservation Type!",
              },
            ]}
          >
            <Radio.Group>
              {indoor===false?  (<Radio.Button className="hidden" value="indoor" disabled>Indoor</Radio.Button>) : (<Radio.Button value="indoor" checked>Indoor</Radio.Button>)}
              {outdoor===false?  (<Radio.Button className="hidden" value="outdoor" disabled >Outdoor</Radio.Button>) : (<Radio.Button value="outdoor" checked >Outdoor</Radio.Button>)}
              {view===false?  (<Radio.Button className="hidden" value="view" disabled >View</Radio.Button>) : (<Radio.Button   value="view" checked >View</Radio.Button>)}
              {any===false?  (<Radio.Button className="hidden" value="any" disabled >Any</Radio.Button>) : (<Radio.Button value="any" checked>Any</Radio.Button>)}
            </Radio.Group>
          </Form.Item>

          
            {hasManySection ? 
            (
              <Form.Item
                name="sectionType"
                label="Section"
                rules={[
                  {
                    required: true,
                    message: "Please Choose Section Type!",
                  },
                ]}
              >
              <Radio.Group>
              <Radio.Button  checked value="single" >Single</Radio.Button>) 
              <Radio.Button  checked value="family" >Family</Radio.Button>) 
              <Radio.Button  checked value="family_partition" >Family partition</Radio.Button>) 
            </Radio.Group>
          </Form.Item>
            )
            :
            (
              ""
            )}
            
    
          <Form.Item label="Guest Size">
            <Form.Item name="size" noStyle
            rules={[
              {
                required: true,
                message: "Please input how many guest !",
              },
            ]}
            >
              {/* <InputNumber min={1} max={60}  /> */}
              {getGuestSize===false?  
              (
                <Select>
                </Select>
                ) 
                : 
                (
                <Select>
                    {Array.range(0,getGuestSize).map((element, i) => <Option key={i} value={element}>{element}</Option>)}
                </Select>
                )}


            </Form.Item>
            {/* <span className="ant-form-text"> machines</span> */}
          </Form.Item>

          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: "Please input your name",
              },
            ]}
          >
            <Input placeholder="Please input your name"  />
          </Form.Item>

          <Form.Item
            name="mobile"
            label="mobile Number"
            rules={[
              {
                required: true,
                message: "Please input company phone number!",
                pattern: new RegExp(/[0-9]{8}/),
              },
              { max: 8, message: "Max digits is 8 after 05 !" },
            ]}
          >
            <Input prefix={"05"}  />
          </Form.Item>

        </div>
        {/* <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit" onClick={() => {
                navigate("/dashboard")
                setAddGuestToWaitlist(false)
              }} >
              Add
            </Button>
            <Button
              
              htmlType="button"
              onClick={() => {
                // navigate("/dashboard")
                setAddGuestToWaitlist(false)
              }}
            >
              Back
            </Button>
          </Space>
        </Form.Item> */}
      </Form>
      </div>
    </Content>
    
    </Modal>
  );
};

export default AddWL;
