import {
  SearchOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import {
  Table,
  Input,
  InputNumber,
  Form,
  Button,
  Space,
  Layout,
  // DatePicker
} from "antd";
import Highlighter from "react-highlight-words";
import React, { useEffect ,useState } from "react";
// import moment from "moment";
import { allCustomer, exportToCSV } from "../api";
import useWindowDimensions from "./useWindowDimensions"

const { Content } = Layout;
// const { RangePicker } = DatePicker;

// function onChange(dates, dateStrings) {
 
// }

export default function MyCustomer({ branch,token }) {
  const [form] = Form.useForm();
  const [data, setData] = useState();

  useEffect(() => {
    if(branch)
    {
      allCustomer(token,branch._id)
        .then((res) => {
          var arr = [];
          var options = {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
          };
          res.data.guests.forEach((guest) => {
            arr.push({
              key: guest._id,
              name: guest.name,
              Phone: guest.mobile,
              date: new Date(new Date()).toLocaleString("en-US", options),
              // Area: waitlist.reservationType,
              // Guests: waitlist.size,
              // Status: waitlist.status,
            });
          });
          setData(arr.reverse());
        })
        .catch((error) => console.log(error))

    }

    return () => {};
  }, [branch,token]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    editSearchText(selectedKeys[0]);
    editSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    editSearchText("");
  };

  const [searchText, editSearchText] = useState("");
  const [searchedColumn, editSearchedColumn] = useState(0);

  let searchInput;
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <p>{text}</p>,
      editable: true,
      align: "center",
    },
    {
      title: "Phone",
      dataIndex: "Phone",
      key: "Phone",
      editable: true,
      align: "center",
      ...getColumnSearchProps("Phone"),
    },
    // {
    //   title: "Number Of Visit",
    //   dataIndex: "NumberOfVisit",
    //   key: "NumberOfVisit",
    //   editable: true,
    //   align: "center",
    // },
    {
      title: "Last Visit",
      dataIndex: "date",
      key: "date",
      editable: true,
      align: "center",
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "age" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
      }),
    };
  });

  const { height, width } = useWindowDimensions();
  return (
    <>
    <Content style={{ margin: "-3px 16px 0px" }}>
      <div id="page-titel">
        <p>All Guest</p>
      </div>
      <Space
        direction="vertical"
        size={12}
        style={{
          flexDirection: "row",
          alignSelf: "end",
          padding: "0 40px",
          marginTop:"-100px",
        }}
      >
        {/* <RangePicker
          ranges={{
            Today: [moment(), moment()],
            "This Month": [moment().startOf("month"), moment().endOf("month")],
          }}
          onChange={onChange}
        /> */}
        <Form.Item>
          <div className="ExcelBTN">
          <Button style={{backgroundColor:"#000033"}} type="primary" htmlType="submit" onClick={()=>exportToCSV(token)}>
            Export <DownloadOutlined  style={{fontSize: "18px"}}/>
            {/* Export<DownloadOutlined/> */}
          </Button>
          </div>
        </Form.Item>
      </Space>
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          // bordered
          dataSource={data}
          columns={mergedColumns}
          rowClassName="editable-row"
          size={"middle"}
          pagination={{
            position: ["bottomCenter"],
            // onChange: cancel,

            pageSize: width <= 1024 ? 7 : width <= 1366 ? 11 : 8
          }}
          style={{
            margin:"-62px 16px"
          }}
        />
      </Form>
      </Content>


      {/* <Footer style={{ textAlign: "center" }}>
        <div id="footer">
          <div className="the-end">
            <img src="./Layer.svg" width="20px" alt="logo" />
            <h4>powered by hjz 2020</h4>
          </div>
        </div>
      </Footer> */}


    </>
  );
}

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === "number" ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
