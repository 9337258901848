import React, { useEffect, useState } from "react";
import {Tabs,Typography,Space,Tag} from 'antd'
import { calculateExpDate } from "../../commonFunctions";
import {CloseCircleOutlined ,CheckCircleOutlined}from '@ant-design/icons';
export const { TabPane } = Tabs;
const { Text } = Typography;
const textStyle = {
    fontWeight:"600",
    color:"#000033",
    fontSize:'1.2em'
}
const textExpStyle = {
    fontWeight:"600",
    color:"#000033",
    fontSize:'1em'
}


export default function Subscriptions(props) {
    const [smsSubs,setsmsSubs] = useState([])
    const [userSubs,setuserSubs] = useState([])

    useEffect(() => {
        setuserSubs(props.userSubs)
        setsmsSubs(props.smsSubs)
    },[userSubs,smsSubs])

    let UserSubs = ()=>{
        // let expDate;
        return (
            <Space direction="vertical">
                {props.userSubs.map((elm,index) => (
                        <Space key={index}  id={index} direction="vertical"> 
                            <Text style={textStyle}> &#8226;  {elm.subscriptionType.charAt(0).toUpperCase()+ elm.subscriptionType.slice(1)}</Text>
                            <Space> 
                            {calculateExpDate(elm.subscriptionType, elm.createdAt)> Date.now()?
                            <Tag icon={<CheckCircleOutlined />} color="success">
                                Active
                            </Tag>
                            :
                            <Tag size={"small"}  icon={<CloseCircleOutlined />} color="error">
                                Expired
                            </Tag>
                            }
                            <div  style={textExpStyle}>Expiration Date {calculateExpDate(elm.subscriptionType, elm.createdAt).toLocaleDateString()} </div> 
                            </Space>
                        </Space>
                    ))}
            </Space>
        );
    }

    // const UserSubsSortedAsc = UserSubs.slice().sort((a, b) => Date.parse(a.created) - Date.parse(b.created))
    // let CurrentSub = UserSubsSortedAsc.slice(-1)[0]
    // let a =Date.parse(UserSubs[0].created), b= Date.parse(UserSubs[1].created)
    
    // const smsSubs = props.smsSubs 
    let TotalSms =0 ,SentSms = 0,RemainingSms = 0

    const calculate = new Promise(()=>{
        smsSubs.forEach((element) => {
            TotalSms += element.messageNumberAllowed
            SentSms+= element.messageNumberSent
        })
    });

    calculate.then( RemainingSms=TotalSms-SentSms);

    return (
        <div>
        {userSubs === [] ? "":(
        <div tab={props.tab} key={props.key} {...props}>
            <div  className="group-field sub">
                <div className="first-col" size={"small"} direction="vertical">

                        <div className="group-field">
                            <h3>SMS Credit</h3>
                             <div size="small" >
                                <h4>Remaining SMS Credit:</h4>
                                <Text style={textStyle}>{RemainingSms} </Text>
                            </div>
                             <div size="small" direction="vertical">
                                <h4> Total SMS Sent:</h4>
                                <Text style={textStyle}>
                                    {TotalSms - RemainingSms} <bold>out of</bold> {TotalSms}
                                </Text>
                            </div>
                        </div>
                </div>
                    
                <div className="second-col " direction="vertical">
                    <div className="group-field">
                        <h3>Subscription History</h3>
                        {UserSubs()}
                    </div>

                </div>
            </div>
        </div>
        )}
        </div>
    )
}