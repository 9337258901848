import {useState,useEffect, React} from "react";

import { Layout,Row, Space } from "antd";
import TableWL from "../TableWL";
// import { useNavigate } from "react-router-dom";
// import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
// import {closeApp} from '../api'
import { parseJwt, checkSubStatus } from "../commonFunctions";
// import useAuth from '../auth/useAuth'
const { Content } = Layout;
// const Text = Typography;
// const textStyle = {
//   fontWeight:"600",
//   color:"#000033"
// }

export default function Waitlist({updateBranch,branch,company,token,headSearch , dataWaitList, setDataWaitList}) {
  // const navigate = useNavigate();
  const [filter, setfilter] = useState([]);
  const [cWaitlist, setCWaitlist] = useState({});
  // const [closeAppState, setcloseAppState] = useState(null);
  

  const ParseJwt = new Promise((resolve,reject)=>{
    resolve(parseJwt(token))
  })


  useEffect(() => {
    try{
      if(branch){
        // setcloseAppState(branch.closeApp)
      }
      ParseJwt.then((jwtPayload)=>
        {
          //TODO: Is it should loop though services array or just take first one!!
          let lastSub = jwtPayload.subscription.services.slice(-1)[0]
          return { 
            subType: lastSub.subscriptionType,
            subDate: lastSub.createdAt
          }
        }).then((subDetails)=>
        {
          checkSubStatus(subDetails.subType,subDetails.subDate)
        }).catch((err)=>{})
    }catch(error){

    }

    return () => {};
  }, [branch]);


  const cWl = (wait)=>{
    const count = wait.reduce((acc,current)=>{
      //TODO: Status is it status!!
      if(current.Status === "waiting"){
        acc.waiting++;   
      } 
      if(current.Status==="served"){
        acc.served++;   
      } 
      if(current.Status==="canceled"){
        acc.canceled++;   
      } 
      acc.all++;
      return acc;
    },{
      all:0,
      waiting:0,
      served:0,
      canceled:0
    })
    setCWaitlist(count);
  }


  // function onChange(checked) {
  //   const closed = {
  //     "closeApp":checked
  //   };

  //   closeApp(closed,token)
  //   .then(() => {
  //     setcloseAppState(checked)
  //     updateBranch(checked)
  //   })
  //   .catch(err => {})
  // }
  

  return (
    <>
      <Content className="sub-component body-waitlist">
        <Space style={{ display:'flex'}}  size={"large"} direction="vertical">
          <Row align="middle" justify="center" className="header-waitlist">
              <center className="radio-toolbar">
                <div>
                  <input
                    type="radio"
                    id="all-list"
                    name="list-filter"
                    value="all-list"
                    onClick={() => {
                      setfilter([]);
                    }}
                  />
                  <label
                    htmlFor="all-list"
                    style={{ backgroundColor: "#000033", color: "white" }}
                  >
                    ALL LIST ({cWaitlist.all})
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="waiting-list"
                    name="list-filter"
                    value="waiting-list"
                    onClick={() => {
                      setfilter(["Waiting","Coming"]);
                    }}
                  />
                  <label
                    htmlFor="waiting-list"
                    style={{ backgroundColor: "#FFF6E5", color: "#EDD096" }}
                  >
                    WAITING ({cWaitlist.waiting})
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="served-list"
                    name="list-filter"
                    value="served-list"
                    onClick={() => {
                      setfilter(["Served"]);
                    }}
                  />
                  <label
                    htmlFor="served-list"
                    style={{ backgroundColor: "#EEF9F3", color: "#A6C7BA" }}
                  >
                    SERVED ({cWaitlist.served})
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="canceled-list"
                    name="list-filter"
                    value="canceled-list"
                    onClick={() => {
                      setfilter(["Canceled"]);
                    }}
                  />
                  <label
                    htmlFor="canceled-list"
                    style={{ backgroundColor: "#FDE7EC", color: "#EA6483" }}
                  >
                    CANCELED ({cWaitlist.canceled})
                  </label>
                </div>
              </center>
          </Row>
        </Space>


        <TableWL _filter={filter} branch={branch} company={company} token={token} setWaitlist={cWl} headSearch={headSearch} dataWaitList={dataWaitList} setDataWaitList={setDataWaitList} />
      </Content>
    </>
  );
}
