import React, {useState} from "react";
import axios from 'axios';
import { parseJwt } from "../commonFunctions";
// import { useNavigate } from "react-router";
// import {  profile } from "../api";
import { url } from "../api";
// import { useNavigate } from "react-router-dom";
// import socketIOClient from "socket.io-client"
import SocketSingleton from "./Socket";


const authContext = React.createContext();

function useAuth() {
  const [storedToken] = useState(localStorage.getItem('authtoken'))
  const [storedAuthed] = useState(localStorage.getItem('authed'))
  const [storedSmsStatus] = useState(localStorage.getItem('smsStatus'))

  const [authed, setAuthed] = useState(storedAuthed||false);
  const [token, setToken] = useState(storedToken||null);
  const [smsStatus, setSmsStatus] = useState((storedSmsStatus === 'true' ? true:false) || false );

  let socket = SocketSingleton.getInstance(); 
  const connectAndLogin = (token) => {
    socket.emit('subscribe', token);
  }
  // const getSocket = ()=>{
  //   return socket
  // }
  
  let getToken = ()=>{
    connectAndLogin(token)
    return token
  }

  


  return {
    authed,
    getToken,
    socket,
    smsStatus,
    setSmsStatus(){
      setSmsStatus(false);
      localStorage.setItem('smsStatus',false)
    },
    login(body){
     return axios.post(url + "/api/user",body.values)
      .then((res)=>{
          const datajwt = parseJwt(res.data.accessToken)
          const hasSms = datajwt.subscription.sms.some(s => s.messageNumberSent < s.messageNumberAllowed)
          setAuthed(true);
          setToken(res.data.accessToken)
          localStorage.setItem('authed',true)
          localStorage.setItem('authtoken',res.data.accessToken)
          if(hasSms){
            setSmsStatus(true)
            localStorage.setItem('smsStatus',true)
          }else{
            setSmsStatus(false)
            localStorage.setItem('smsStatus',false)
          }

          return(res.data.accessToken)

        }).catch(function (error) {
          throw error;
        })
    },
    logout() {
      return new Promise((res) => {
        setAuthed(false);
        setToken(null)
        localStorage.clear()
        res();
      });
    }
  };
}

export function AuthProvider({ children }) {
  const auth = useAuth();

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export default function AuthConsumer() {
  return React.useContext(authContext);
}

