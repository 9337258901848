import "./App.css";
import Main from "./components/Main";
import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import NotFound from "./components/NotFound";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
// import { useState, useEffect } from "react";
// import {url} from './components/api';
import useAuth from "./components/auth/useAuth";


function RequireAuth({ children }) {
  const { authed } = useAuth();
  return authed ? children : <Navigate to="/" replace />;
}





function App() {
  
  // const [companyIdState, setCompanyIdState] = useState(null);
  // const [logo, setLogo] = useState("");
  // const [name, setName] = useState("");
  const {authed} = useAuth();
  // function setCompanyId(id) {
  //   setCompanyIdState(id);
  // }


  return (
      <Router>
        <Routes>
          <Route exact path="/" element={
            authed?<Navigate to="/dashboard"/>: <Login/>
          }/>

          <Route path="/register" element={
             authed?<Navigate to="/dashboard"/>: <Register/>
          }
          />
          <Route exact path="/*" element = {
            <RequireAuth>
              <Main/>
            </RequireAuth>

          }/>
          
          <Route path="*" element={        
              <NotFound/>
              }>
          </Route>
        </Routes>
      </Router>
  );
}

export default App;
