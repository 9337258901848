import React, {useState,useEffect} from "react";
import { Layout, Menu,Button,message } from "antd";
import {
  PieChartOutlined,
  BarsOutlined,
  LogoutOutlined,
  FileDoneOutlined,
  SettingOutlined
} from "@ant-design/icons";
import { Routes, Route, Link } from "react-router-dom";
import Waitlist from "./sub-routes-components/WaitList";
import Report from "./sub-routes-components/Report";
import MyCustomer from "./sub-routes-components/MyCustomer";
import SettingMenu from "./sub-routes-components/SettingMenu";
import Logout from "./sub-routes-components/Logout";
import Branch from "./sub-routes-components/Branch";
import AddWL from "./AddWL";
import CustomerShow from "./CustomerShow";
import { getCompany,getBranch, addGuestToWaitlist} from "./api";
import useAuth from './auth/useAuth'
import NotFound from "./NotFound";
import Header from './Header.js';
import {parseJwt} from './commonFunctions'
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'


const {  Sider } = Layout;
const MySwal = withReactContent(Swal)

export default function SiderDemo(props){
  const [dataWaitList, setDataWaitList] = useState(null);
  // const [headSearch, setHeadSearch] = useState("");
  const [headSearch] = useState("");
  // const [logoURL, setLogoURL] = useState("");
  // const [companyName, setCompanyName] = useState("");
  const [company, setCompany] = useState(false);
  const [branch, setBranch] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [getAddGuestToWaitlist, setAddGuestToWaitlist] = useState(false);
  const [jwt, setJWT] = useState({})
  const {getToken,logout} = useAuth();
  
  let token = getToken()
  
  const navigate = useNavigate();
  
  const updateBranch =(checked)=>{
    setIsUpdated(true);
    setCompany({
      ...branch
    })
  }
  
  // window.onload = function() {
  //   navigate(window.location.pathname)
  // };

  const onCreateWaitlist = (values) => {
    console.log('values',values);
    const body = {
      waitlist:{
              reservationType: values.reservationType,
              guest_size: values.size,
      },
      guest: {
        name: values.name,
        mobile:  "05" + values.mobile,
      }
    };
    if(branch.hasManySection){
      body.waitlist.sectionType = values.sectionType
    }
    setAddGuestToWaitlist(false);
    addGuestToWaitlist(branch._id, token, body)
      .then(() => {
        message.success("Created Success!");
        //TODO: we should reload the page here & add waitlist to array
        navigate("/");
      })
      .catch(() => {
        message.warn("Created Failed!");
        navigate("/");
      });
  };
  
  useEffect(() => {
    const objectJWT = parseJwt(token);
    console.log("JWT",objectJWT);
    setJWT(objectJWT)
    try{
      if(!company || isUpdated){
        getCompany(token)
        .then((res) => {
          console.log("CMPANY",res.data);
          setCompany(res.data)
          // setLogoURL(res.data.logo);
          // setCompanyName(res.data.name)
          if(objectJWT.branches.length === 0){
            console.log('please create a branch first, redirect');
            MySwal.fire({
                title: <strong>Create Branch</strong>,
                html: <i>Please, you need to create a branch to use app!!</i>,
                icon: 'info'
            })
            navigate('/new-branch')
            setBranch(false);
          }else{
            getBranch(token,objectJWT.branches[0])
            .then(res => {
              console.log("BRANCH",res.data);
              setBranch(res.data);
            })
            .catch(err => console.log(err))
          }
        })
         .catch(err => console.log(err))
      }
    }
    catch(e){
      console.log('company corrupted!')
    }

    return () => {
    };
  }, [company,isUpdated,navigate,token]);
  


  // const { Search } = Input;

  // const onSearch = (value) => setHeadSearch(value);



  return (
    <Layout className="main-component">
      <Header setCompany={setCompany} branch={branch} company={company} jwt={jwt} token={token}/>
      <Layout  className="sub-component">
          <Sider id="sider">
              <div className="item-menu box-add ">
                  <Button id="addGuest" type="primary" onClick={()=>{
                    setAddGuestToWaitlist(true)
                    navigate('/dashboard');
                  }}>
                    <p>+</p> 
                  </Button>
              </div>

              <Menu theme="light" mode="inline" defaultSelectedKeys={['1']}  >  
                
                <Menu.Item className="item-menu" key="1"  icon={<BarsOutlined />}>
                  <Link id="menuItem" to="/dashboard">Waitlist</Link>
                </Menu.Item>
                {/* <Menu.Item  className="item-menu" key="2"  icon={<AppstoreAddOutlined />}>
                  <Link id="menuItem" to="/dashboard">Floor</Link>
                </Menu.Item> */}
                <Menu.Item  className="item-menu" key="3" icon={<PieChartOutlined />}>
                  <Link id="menuItem" to="report">Report</Link>
                </Menu.Item>
                <Menu.Item  className="item-menu" key="4" icon={<FileDoneOutlined />}>
                  <Link id="menuItem" to="my-customer">Guests</Link>
                </Menu.Item>  

                <Menu.Item className="item-menu" key="5" icon={<SettingOutlined />}>
                  <Link id="menuItem" to="settings">Settings</Link>
                </Menu.Item>
                
                <Menu.Item className="item-menu" key="6" icon={<LogoutOutlined id="logout-icon"/>}>
                  <a
                 
                    href="/"
                    onClick={() => {
                      logout()
                    }}
                  >
                    Logout
                  </a>
                </Menu.Item>

               
                

                <div key="7" id="mini-logo item-menu">
                  <div className="the-end">
                    <img src="./Layer.svg" alt="logo" style={{marginBottom: "3px", width: "25%"}} />
                  </div>
                </div>

              </Menu>
          </Sider>
          
          <Layout
            className="content-layout"
          >
            <Routes>
              <Route exact path={"/dashboard"} element={
                <div className="addGuest-to-waitlist">
                  <Waitlist branch={branch} updateBranch={updateBranch} company={company} token={token} headSearch={headSearch} dataWaitList={dataWaitList} setDataWaitList={setDataWaitList}/>
                  {getAddGuestToWaitlist ? <AddWL onCancel={() => {setAddGuestToWaitlist(false)}} onCreateWaitlist={onCreateWaitlist} getAddGuestToWaitlist={getAddGuestToWaitlist} setAddGuestToWaitlist={setAddGuestToWaitlist} branch={branch} token={token} />:"" }
                </div>
              }
              />
              <Route  path="/report" element={
                <Report branch={branch} token={token}/>
              }/>
              <Route  path="/my-customer" element={
                <MyCustomer branch={branch} token={token}/>
              }/>
              <Route  path="/settings" element={
                <SettingMenu  setCompany={setCompany} branch={branch} company={company} token={token} />
              }/>
              <Route  path="/new-branch" element={
                <Branch  company={company} token={token} />
              }/>
              <Route  path="/dashboard/CustomerShow/:id" element={
                <CustomerShow token={token} dataWaitList={dataWaitList}/>
              }/>
              {/* <Route exact path="/dashboard" element={
                
              }/> */}
              <Route  path="/logout" element={
                <Logout  />
              }/>
              <Route path="*" element={         
                <NotFound/>
              }>
              </Route>
            </Routes>
          </Layout>
      </Layout>
    </Layout>
  );
  
}
