// import React, { useState } from "react";
import {updateCompany} from '../../api';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import {
  UserOutlined,
  TwitterOutlined,
  InstagramOutlined,
  WhatsAppOutlined
} from "@ant-design/icons";

import {
  Form,
  message, 
  Input,
  Button,
  Avatar
} from "antd";


const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

const MySwal = withReactContent(Swal)


export default function MainSettings({company,token}) {
    const onFinish = (value) => {
        const companyData = {
                "name": value.name,
                "logo": value.logo,
                "menu": value.menu,
                "socialMedia": []
        }
        if(value.twitter !== "")
            companyData.socialMedia.push({"name": "twitter","url": value.twitter})
        if(value.instagram !== "")
            companyData.socialMedia.push({"name": "instagram","url": value.instagram})
        if(value.whatsapp !== "")
            companyData.socialMedia.push({"name": "whatsapp","url": value.whatsapp})

        updateCompany(token, companyData)
        .then(async (res) => {
            await MySwal.fire({
                title: <strong>Success</strong>,
                html: <i>COMPANY UPDATED!!</i>,
                icon: 'success'
            })
            
        })
        .catch(error => {
            Swal.fire({
                icon: 'error',
                title: 'Oops..Error.',
                text: error.response.data.error,
                footer: '<a href="https://hjz.sa/">Why do I have this issue? Contact hjz Team</a>'
                })
        })



    }


    const onFinishFailed = () => {
        message.error("Submit failed!");
    };

    const validateMessages = {
        required: "${label} is required!",
        types: {
            email: "${label} is not a valid email!",
            number: "${label} is not a valid number!",
            text: "${label} is not a valid text!",    },
        number: {
            range: "${label} must be between ${min} and ${max}",
        },
    };


    return(

        <div>
         {company === false ? 
         (
            <p>loading...</p>
         )
          : 
         (
            
            <Form
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                {...layout}
                name="nest-messages"
                validateMessages={validateMessages}
                colon={false}
                initialValues={{
                    name: company.name ? company.name : "",
                    logo: company.logo ? company.logo : "",
                    menu: company.menu ? company.menu : "",
                    twitter: company.socialMedia[0] ? company.socialMedia[0].url : "",
                    instagram: company.socialMedia[1] ? company.socialMedia[1].url : "",
                    whatsapp: company.socialMedia[2] ? company.socialMedia[2].url : "",

                }}
            >
                
                <div className="form-container">
                    <div  className="first-col">
                        <div className="group-field">
                             <h3>General Information</h3>
                            <Form.Item
                                className="lable-form "
                                label="Company Name"
                                name="name"
                                rules={[
                                    {
                                        max:14,
                                        message: "Value should be less than or equal 14 character",
                                    },
                                    {
                                        required: true,
                                        message: "Please enter your company name!",
                                    },
                                ]}>
                                <Input type="text"/>
                            </Form.Item>

                        </div>
                        <div className="group-field">
                            <h3 >Social Media</h3>
                            <Form.Item
                                className="lable-form social-media-field"
                                name="twitter"
                                rules={[ { type: 'url', warningOnly: true }, { type: 'string', min: 6 }]}
                            >
                                <Input prefix={<TwitterOutlined />}  placeholder="twitter link" />
                            </Form.Item>
                            <Form.Item
                                className="lable-form social-media-field"
                                name="instagram"
                                rules={[ { type: 'url', warningOnly: true }, { type: 'string', min: 6 }]}
                            >
                                <Input prefix={<InstagramOutlined />}  placeholder="instagram link" />
                            </Form.Item>
                            <Form.Item
                                className="lable-form social-media-field"
                                name="whatsapp"
                                label=""
                                rules={[ { type: 'url', warningOnly: true }, { type: 'string', min: 6 }]}
                            >
                                <Input prefix={<WhatsAppOutlined />} placeholder="whatsapp link"  />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="second-col">
                            <Form.Item>
                                <Button
                                    className="update"
                                    type="primary"
                                    htmlType="submit">
                                    <p>Update</p>
                                </Button>
                            </Form.Item>
                            <div className="group-field">
                                <h3>Media Information</h3>
                                <Form.Item
                                    className="lable-form ant-form-item">
                                    <div>
                                        <label className="ant-form-item-required ant-form-item-no-colon">Company LOGO</label>
                                    </div>
                                    <div style={{ margin: "13px" }}>
                                        <Avatar
                                            size={100}
                                            src={company.logo}
                                            icon={<UserOutlined />}
                                        />
                                    </div>
                                </Form.Item>

                                <Form.Item
                                    className="lable-form"
                                    name="logo"
                                    label="Logo link"
                                    rules={[{ required: true }, { type: 'url', warningOnly: true }, { type: 'string', min: 6 }]}
                                >
                                    <Input placeholder="Enter the Logo link" />
                                </Form.Item>

                                <Form.Item
                                    className="lable-form"
                                    name="menu"
                                    label="Menu link"
                                    rules={[{ required: true }, { type: 'url', warningOnly: true }, { type: 'string', min: 6 }]}
                                >
                                    <Input placeholder="Enter the menu link" />
                                </Form.Item>

                        </div>
                    </div>
                </div>
            </Form>
            )}
            </div>
    )
}
