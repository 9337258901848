import Swal from 'sweetalert2'

export const subscriptionAlert = ()=>{ 
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      html: `Your subscription has expired, 
      please contact us <br/>
      Email: contact@hjz.sa <br/>
      WhatsApp: 0533202989`
      // footer: '<a href="">Why do I have this issue?</a>'
    })
}


const now = new Date(Date.now());
export const checkSubStatus= function(subType,subDate){
    let subExpiration= calculateExpDate(subType,subDate)
    //subExpiry= subExpiry.addDays(14)
    if(subExpiration !=  null && now > subExpiration  ){
      subscriptionAlert()
    }
}
export const calculateExpDate = function(subType,subDate){
    let expDate = new Date(subDate);
    if(subType==='trial')
        return expDate.addDays(14)
    else if(subType ==='quarter')
        return expDate.addDays(90)
    else if(subType === 'year')
        return expDate.addDays(360)
    return null
}

export function parseJwt(token) {
    if (!token) { return; }
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
}

Date.prototype.addDays= function(days){
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

