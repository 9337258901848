import React, {useEffect} from "react";
// import { Switch,message } from "antd";
import Moment from 'react-moment';
import 'moment-timezone';
import moment from 'moment'
// import { updateBranch } from "./api";
import {
   ReloadOutlined
 } from '@ant-design/icons';



export default function Header({branch, jwt,company,token}){
   // const [getTime, setTime] = useState(false)
   // const [appStatus, setAppStatus] = useState(branch.closeApp)

   const onChange = (value) => {
      window.location.reload();
      return false;
      // branch.closeApp = value
      // updateBranch(token, branch, branch._id)
      //   .then(async (res) => {
      //       setAppStatus(value)
      //       message.success(`The data is loaded!`);
      //   })
      //   .catch((error) => {
      //       message.warn("It Failed!" + `(${error.response.data.error})`);
      //   })
   }

   
   useEffect(() => {
      const timer = setTimeout(() => {
        const elem = document.getElementById('reload');
        elem.classList.remove("anticon-spin")
      }, 1000);
      return () => clearTimeout(timer);
    }, [branch]);

      return (
            <div id="header-component">
             <div className="header-company-name">
               {jwt === {} || company === false ? "":
               (
               <div>
                  <h1> {company.name} <span className="header-title-branch">{branch.areaName}</span> </h1>
                  <h5><span style={{fontWeight:"bold"}}>Welcome</span> {jwt.fullName} ({jwt.role}) </h5>
               </div>
                )}
             </div>
             <div className="header-company-date">
                {jwt === {} || company === false  ? "":(<h4><Moment format="dddd - MMM Do YYYY">{moment().tz(jwt.zone.name)}</Moment></h4>)}
             </div>
             <div className="header-company-controll">
               <ReloadOutlined style={{ fontSize: '1.5em' }} id="reload"  spin onClick={onChange}/>
             </div>
            </div>
         
      );

}
