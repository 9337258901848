import React, {useEffect, useState} from "react";
import {getSections, getTables, deleteSection, createSection} from "../../api";
import AddSection from "./AddSection";
import {Table, Space, Button} from 'antd';
import {UsergroupAddOutlined} from "@ant-design/icons"
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

export default function Sections({token, branch}) {
    const [getSectionsData, setSectionsData] = useState([]);
    const [getTablesData, setTablesData] = useState([]);
    const [visible, setVisible] = useState(false);
 
    useEffect(()=>{
        getSections(token, branch._id)
        .then((res)=>{
          setSectionsData(res.data)
        }).catch((err)=>{
          console.log(err);
        }
        )
    },[token, branch]);
    
    useEffect(()=>{
      // console.log(getSectionsData);
      getSectionsData.map((sec)=>{
        getTables(token, branch._id, sec._id)
        .then((res)=>{
          setTablesData(()=>{
            return([...res.data])})})
        .catch((err)=>{
          console.log(err);
        })
      })
  },[token, branch, getSectionsData]);
  //  console.log("xx",getTablesData);

   const onClick = (id) => {
    // console.log("id",id)

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        deleteSection(token,branch._id,id)
        .then(() => {
          Swal.fire(
            'Deleted!',
            'Section has been deleted.',
            'success'
          )

          const newSections = getSectionsData.filter((sec)=> sec._id !== id );
          setSectionsData(newSections);

        })
        .catch((error) => {
          console.log(error)
        })
      }
    })
}
const onCreate = (values) => {
  setVisible(false);
  createSection(token, branch._id, values)
  .then(() => {
    MySwal.fire({
            title: <strong>Success</strong>,
            html: <i>Section Added Success!</i>,
            icon: 'success'
    })
  })
  .catch((error) => {
     Swal.fire({
      icon: 'error',
      title: "Oops..Can't add Section!",
      text: error.response.data.error,
      footer: '<a href="https://hjz.sa/">Why do I have this issue? Contact hjz Team</a>'
      })
  })
 
};

    const expandedRowRender = () => {
        const columns = [
          { title: 'Name', 
          dataIndex: 'name', 
          key: 'name' 
          },
          { title: 'Size', 
          dataIndex: 'size', 
          key: 'size' 
          },
        ];

        const data =getTablesData.map(table => {
          return (
            {
              key: table._id,
              name:table.name,
              size:table.size
            })
        });

        return <Table columns={columns} 
              dataSource={data} 
              pagination={false} 
        />;
      };
    
      const columns = [
        { title: 'Name', dataIndex: 'name', key: 'name' },
        { title: 'CreatedAt', dataIndex: 'createdAt', key: 'createdAt' },
        {
          title: 'Action',
          key: 'action',
          dataIndex: 'key',
          render: key=> (
              <Space size="middle">
                  <a  key={key} onClick={() => onClick(key)}>Delete </a>
              </Space>
          )}
      ];
      const data =getSectionsData.map(section => {
        return (
          {
            key: section._id,
            name:section.name,
            createdAt:section.createdAt
          })
      });
    
      return (
        <div >
          <div>
            <Button
              type="primary"
              style={{backgroundColor:"#000032",borderColor:"#000032", marginBottom:"2%" }}
              onClick={() => {
                setVisible(true);
              }}
            ><UsergroupAddOutlined/> Add Section</Button>
            <AddSection
              visible={visible}
              onCreate={onCreate}
              onCancel={() => {
                setVisible(false);
              }}
            />
          </div>
          <Table className="sections"
           dataSource={data}
           columns={columns}
           expandable={{ expandedRowRender }}/>
        </div>
      );

}