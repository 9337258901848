import React, { useEffect, useState } from "react";
import {updateBranch} from '../../api';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import {
  UserOutlined,
  DashboardOutlined,
  UsergroupAddOutlined,
  MinusCircleOutlined, 
  PlusOutlined,
  DownloadOutlined
} from "@ant-design/icons";

import {
  Form,
  message, 
  Input,
  Button,
  Avatar,
  InputNumber,
  Checkbox,
  Row,
  Switch,
  TimePicker
} from "antd";
import moment from 'moment';




const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

const MySwal = withReactContent(Swal)

export default function BranchSettings({branch,company,token}) {
    const [getBranch, setBranch] = useState(branch)
    const [any, setAny] = useState(false);
    const [indoor, setIndoor] = useState(false);
    const [outdoor, setOutdoor] = useState(false);
    const [view, setView] = useState(false);
    
    
    const {RangePicker} = TimePicker;
    
    
    function onChangeTableSwitch(checked) {
        getBranch.sendWithTableNumber = checked
        setBranch({...getBranch});
    }

    function onChangeAppSwitch(checked) {
        getBranch.closeApp = checked
        setBranch({...getBranch});
    }

    function onChangeAppSection(checked) {
        getBranch.hasManySection = checked
        setBranch({...getBranch});
    }

    function onChangeAppConfirmationPage(checked) {
        getBranch.alwaysConfirmGuest = checked
        setBranch({...getBranch});
    }

    function onChangeAppShowQueueLength(checked) {
        getBranch.showQueueLength = checked
        setBranch({...getBranch});
    }


    const onFinish = (body) => {        
        body.workHours = getBranch.workHours    

        const reservationTypes = [];
        if (any) 
            reservationTypes.push("any");
        if (indoor) 
            reservationTypes.push("indoor");
        if (outdoor) 
            reservationTypes.push("outdoor");
        if (view) 
            reservationTypes.push("view");

        body.reservationTypes = reservationTypes;

        updateBranch(token, body, getBranch._id)
        .then(async (res) => {
            await MySwal.fire({
                title: <strong>Success</strong>,
                html: <i>BRANCH UPDATED!!</i>,
                icon: 'success'
            })
            
        })
        .catch((error) => {
            Swal.fire({
                icon: 'error',
                title: 'Oops..Error.',
                text: error.response.data.error,
                footer: '<a href="https://hjz.sa/">Why do I have this issue? Contact hjz Team</a>'
                })
        })
    }

    const onFinishFailed = () => {
        message.error("Submit failed!");
    };

    const validateMessages = {
        required: "${label} is required!",
        types: {
            email: "${label} is not a valid email!",
            number: "${label} is not a valid number!",
            text: "${label} is not a valid text!",    },
        number: {
            range: "${label} must be between ${min} and ${max}",
        },
    };

    // const prefixSelector = (
    //     <Form.Item noStyle>
    //     <Select value="966" style={{ width: 80 }}>
    //         <Option value="966" >+966</Option>
    //     </Select>
    //     </Form.Item>
    // );

    const onDownload = () => {
        const link = document.createElement("a");
        link.download = getBranch.qrCode;
        link.href = getBranch.qrCode;
        link.click();
    };


    const onChangeTime = (time,timeString,{day}) => {
        const numStartHrs = timeString[0][0] + timeString[0][1]
        const numStartMins = timeString[0][3] + timeString[0][4]

        const numEndHrs = timeString[1][0] + timeString[1][1]
        const numEndMins = timeString[1][3] + timeString[1][4]

        const periods = [{
            starts: {hours: parseInt(numStartHrs), minutes: parseInt(numStartMins)},
            ends: {hours:parseInt(numEndHrs), minutes: parseInt(numEndMins)}
        }];

        const newWorkHours = getBranch.workHours;
        newWorkHours.forEach( element => {
            if(element.day === day){
                element.periods = periods
            }
        });

        setBranch((preBranch) => ({
            ...preBranch,
            workHours:newWorkHours
        }));
    }
    

    useEffect(() => {
        try{
            setBranch(branch);

            branch.reservationTypes.forEach((item) => {
                if (item === "any") {
                    setAny(true);
                }
                if (item === "indoor") {
                    setIndoor(true);
                }
                if (item === "outdoor") {
                    setOutdoor(true);
                }
                if (item === "view") {
                    setView(true);
                }
            });
        }catch(e){
    
        }
    },[branch]); 

   


    return(
            <Form
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                {...layout}
                name="nest-messages"
                validateMessages={validateMessages}
                colon={false}
                initialValues={{
                    city: getBranch.city ? getBranch.city: "",
                    areaName: getBranch.areaName ? getBranch.areaName: "",
                    mobile: getBranch.mobile ? getBranch.mobile: "",
                    reservationTypes: getBranch.reservationTypes,
                    cancelReservationTime: getBranch.cancelReservationTime,
                    maxGuestPerTable: getBranch.maxGuestPerTable ? getBranch.maxGuestPerTable: "",
                    conditions: getBranch.conditions ? getBranch.conditions: [],
                    workHours: getBranch.workHours ? getBranch.workHours: [],
                    sendWithTableNumber: getBranch.sendWithTableNumber ? getBranch.sendWithTableNumber: false,
                    closeApp: getBranch.closeApp ? getBranch.closeApp: false,
                    hasManySection: getBranch.hasManySection ? getBranch.hasManySection: false,
                    alwaysConfirmGuest: getBranch.alwaysConfirmGuest ? getBranch.alwaysConfirmGuest: false,
                    showQueueLength: getBranch.showQueueLength ? getBranch.showQueueLength: false
                }}
            >
                <div className="form-container">
                    <div  className="first-col">
                        <div className="group-field">
                             <h3>Branch Information</h3>
                            <Form.Item
                                className="lable-form "
                                label="City Name"
                                name="city"
                                rules={[
                                    {
                                        max:14,
                                        message: "Value should be less than or equal 14 character",
                                    },
                                    {
                                        required: true,
                                        message: "Please enter city name!",
                                    },
                                ]}>
                                <Input  type="text"/>
                            </Form.Item>

                            <Form.Item
                                className="lable-form "
                                label="Are Name"
                                name="areaName"
                                rules={[
                                    {
                                        max:14,
                                        message: "Value should be less than or equal 14 character",
                                    },
                                    {
                                        required: true,
                                        message: "Please enter city name!",
                                    },
                                ]}>
                                <Input  type="text"/>
                            </Form.Item>

                             <Form.Item
                                className="lable-form"
                                name="mobile"
                                label="Mobile Number"
                                rules={[{
                                        max:10,
                                        min:10,
                                        message: "Value should be less equal 10 number",
                                    },{ 
                                        required: true, message: 'Please input branch mobile number!' }]}
                                >
                                <Input    disabled={false} />
                            </Form.Item>

                                
                            <div className="flex-box">
                                <Form.Item name="cancelReservationTime" >
                                    <Form.Item
                                        className="lable-form textCenter"
                                        label="Reservation cancele"
                                    >
                                        <h5 style={{ fontWeight : "900" }}>
                                            per minute <DashboardOutlined />
                                        </h5>
                                        <InputNumber 
                                        className="textCenter"
                                                min={1}
                                                max={100}
                                                size="large" 
                                                placeholder={getBranch.cancelReservationTime}
                                                defaultValue={getBranch.cancelReservationTime}        
                                        />
                                    </Form.Item>
                                </Form.Item>
                                 <Form.Item  name="maxGuestPerTable">
                                    <Form.Item
                                        className="lable-form textCenter"
                                        label="Max guests for the table"
                                        rules={[{
                                            max:9,
                                            min:1,
                                            message: "Size should be less than or equal 9 guests",
                                        },{ required: true, message: "Missing Max guests for the table" }]}
                                    >
                                        <h5 style={{ fontWeight : "900" }}>
                                            per table<UsergroupAddOutlined />
                                        </h5>
                                        <InputNumber 
                                        className="textCenter"
                                                min={1}
                                                max={9}
                                                size="large" 
                                                placeholder={getBranch.maxGuestPerTable}
                                                defaultValue={getBranch.maxGuestPerTable}

                                        />
                                    </Form.Item>
                                </Form.Item>
                            </div>
                        </div>
                    </div>

                    <div className="second-col">
                            <Form.Item className="update-index">
                                <Button
                                    className="update"
                                    type="primary"
                                    htmlType="submit">
                                    <p>Update</p>
                                </Button>
                            </Form.Item>
                            <div className="group-field">
                                <h3>Customization</h3>
                                <Form.Item 
                                    className="lable-form"
                                    label="Reservation Area"
                                    name="reservationTypes" >
                                    <Checkbox.Group className="reservationTypes">
                                        <Row>
                                        <label htmlFor="chk1">
                                            {indoor === false ? (
                                            <Checkbox
                                                id="chk1"
                                                type="checkbox"
                                                value="indoor"
                                                size="small"
                                                onClick={(e) => {
                                                    setIndoor(!indoor);
                                                }}
                                            />
                                            ) : (
                                            <Checkbox
                                                key="indoor"
                                                id="chk1"
                                                type="checkbox"
                                                value="indoor"
                                                checked
                                                size="small"
                                                onClick={(e) => {
                                                    setIndoor(!indoor);
                                                }}
                                            />
                                            )}
                                            &nbsp;&nbsp; Indoor
                                        </label>
                                        
                                        <label htmlFor="chk2">
                                            {outdoor === false ? (
                                            <Checkbox
                                                id="chk2"
                                                type="checkbox"
                                                value="outdoor"
                                                size="small"
                                                onClick={(e) => {
                                                    setOutdoor(!outdoor);
                                                }}
                                            />
                                            ) : (
                                            <Checkbox
                                                id="chk2"
                                                type="checkbox"
                                                value="outdoor"
                                                size="small"
                                                checked
                                                onClick={(e) => {
                                                    setOutdoor(!outdoor);
                                                }}
                                            />
                                            )}
                                            &nbsp;&nbsp; Outdoor
                                        </label>
                                        
                                         <label htmlFor="chk3">
                                            {view === false ? (
                                            <Checkbox
                                                id="chk3"
                                                type="checkbox"
                                                value="view"
                                                size="small"
                                                onClick={(e) => {
                                                    setView(!view);
                                                }}
                                            />
                                            ) : (
                                            <Checkbox
                                                id="chk3"
                                                type="checkbox"
                                                value="view"
                                                size="small"
                                                checked
                                                onClick={(e) => {
                                                    setView(!view);
                                                }}
                                            />
                                            )}
                                            &nbsp;&nbsp; View
                                        </label>
                                        
                                        <label htmlFor="chk4">
                                            {any === false ? (
                                            <Checkbox
                                                id="chk4"
                                                type="checkbox"
                                                value="any"
                                                size="small"
                                                onClick={(e) => {
                                                setAny(!any);
                                                }}
                                            />
                                            ) : (
                                            <Checkbox
                                                id="chk4"
                                                type="checkbox"
                                                value="any"
                                                size="small"
                                                checked
                                                onClick={(e) => {
                                                setAny(!any);
                                                }}
                                            />
                                            )}
                                            &nbsp;&nbsp; Any
                                        </label>
                                        
                                        </Row>
                                    </Checkbox.Group>
                                </Form.Item>

                                <Form.Item 
                                    className="lable-form"
                                    label="Send table number with SMS?"
                                    name="sendWithTableNumber"  >
                                    {getBranch.sendWithTableNumber ?  
                                            <Switch size="large"  checkedChildren="YES" unCheckedChildren="NO"  checked onChange={onChangeTableSwitch}
                                            style={{
                                                backgroundColor:'rgb(82, 196, 26)'
                                            }}
                                            />
                                            :
                                            <Switch size="large" checkedChildren="YES" unCheckedChildren="NO"  onChange={onChangeTableSwitch}
                                            style={{
                                                backgroundColor:'rgb(196, 26, 26)'
                                            }}
                                            />
                                    }
                                </Form.Item>

                                <Form.Item 
                                    className="lable-form"
                                    label="Would you like to close the system?"
                                    name="closeApp"  >
                                    {getBranch.closeApp ?  
                                            <Switch size="large"  checkedChildren="YES" unCheckedChildren="NO"  checked onChange={onChangeAppSwitch}
                                            style={{
                                                backgroundColor:'rgb(82, 196, 26)'
                                            }}
                                            />
                                            :
                                            <Switch size="large" checkedChildren="YES" unCheckedChildren="NO"  onChange={onChangeAppSwitch}
                                            style={{
                                                backgroundColor:'rgb(196, 26, 26)'
                                            }}
                                            />
                                    }
                                </Form.Item>

                                <Form.Item 
                                    className="lable-form"
                                    label="Do you have family Section?"
                                    name="hasManySection"  >
                                    {getBranch.hasManySection ?  
                                            <Switch size="large"  checkedChildren="YES" unCheckedChildren="NO"  checked onChange={onChangeAppSection}
                                            style={{
                                                backgroundColor:'rgb(82, 196, 26)'
                                            }}
                                            />
                                            :
                                            <Switch size="large" checkedChildren="YES" unCheckedChildren="NO"  onChange={onChangeAppSection}
                                            style={{
                                                backgroundColor:'rgb(196, 26, 26)'
                                            }}
                                            />
                                    }
                                </Form.Item>

                                <Form.Item 
                                    className="lable-form"
                                    label="Disable the confirmation page?"
                                    name="alwaysConfirmGuest"  >
                                    {getBranch.alwaysConfirmGuest ?  
                                            <Switch size="large"  checkedChildren="YES" unCheckedChildren="NO"  checked onChange={onChangeAppConfirmationPage}
                                            style={{
                                                backgroundColor:'rgb(82, 196, 26)'
                                            }}
                                            />
                                            :
                                            <Switch size="large" checkedChildren="YES" unCheckedChildren="NO"  onChange={onChangeAppConfirmationPage}
                                            style={{
                                                backgroundColor:'rgb(196, 26, 26)'
                                            }}
                                            />
                                    }
                                </Form.Item>

                                <Form.Item 
                                    className="lable-form"
                                    label="Show Queue length for guest?"
                                    name="showQueueLength"  >
                                    {getBranch.showQueueLength ?  
                                            <Switch size="large"  checkedChildren="YES" unCheckedChildren="NO"  checked onChange={onChangeAppShowQueueLength}
                                            style={{
                                                backgroundColor:'rgb(82, 196, 26)'
                                            }}
                                            />
                                            :
                                            <Switch size="large" checkedChildren="YES" unCheckedChildren="NO"  onChange={onChangeAppShowQueueLength}
                                            style={{
                                                backgroundColor:'rgb(196, 26, 26)'
                                            }}
                                            />
                                    }
                                </Form.Item>
                                
                                <Form.Item 
                                    label="Add conditions for Guest"
                                    className="lable-form"
                                    name="conditions" 
                                >
                                    <Form.List
                                        name="conditions"
                                        rules={[
                                        {
                                            validator: async (_, conditions) => {
                                            if (conditions.length > 6) {
                                                return Promise.reject(new Error('Max is 6 conditions'));
                                            }
                                            },
                                        },
                                        ]}
                                    >
                                            {(fields, { add, remove }, { errors }) => (
                                            <>
                                                {fields.map((field, index) => (
                                                <Form.Item
                                                  
                                                    label={index === 0 ? 'conditions' : ''}
                                                    required={false}
                                                    key={field.key}
                                                >
                                                    <Form.Item
                                                    {...field}
                                                    validateTrigger={['onChange', 'onBlur']}
                                                    placeholder=""
                                                    rules={[
                                                        {
                                                        required: true,
                                                        whitespace: true,
                                                        message: "Please input condition or delete this field.",
                                                        },
                                                    ]}
                                                    noStyle
                                                    >
                                                    <Input placeholder="ممنوع دخول الاطفال اقل من سنة" style={{ width: '90%' ,textAlign: "center"}} />
                                                    </Form.Item>
                                                    {fields.length > 0 ? (
                                                    <MinusCircleOutlined
                                                        className="dynamic-delete-button"
                                                        onClick={() => remove(field.name)}
                                                    />
                                                    ) : null}
                                                </Form.Item>
                                                ))}
                                                <Form.Item>
                                                <Button
                                                    type="dashed"
                                                    onClick={() => add()}
                                                    style={{ width: '90%' }}
                                                    icon={<PlusOutlined />}
                                                >
                                                    Add field
                                                </Button>
                                                <Form.ErrorList errors={errors} />
                                                </Form.Item>
                                            </>
                                            )}
                                    </Form.List>
                               </Form.Item>
                        </div>
                    </div>
                </div>
                <div className="group-field ">
                            <h3 >Wrok hours</h3>
                            <div className="work-hours">
                                <div className="first-col" >
                                    {getBranch.workHours.map((element,index) => 
                                        (   
                                            <Form.Item key={element.day}>
                                                <Form.Item  
                                                    label={element.day.toUpperCase()} 
                                                    key={element.day}
                                                    fieldKey={element.day}
                                                    
                                                    // rules={[{ required: true, message: "Missing workhours" }]}
                                                    >
                                                    <RangePicker
                                                        showTime={{ format: "HH:mm" , use12Hours:false}}
                                                        format="HH:mm"
                                                        defaultValue={[moment(`2022-03-01 ${element.periods[0].starts.hours}:${element.periods[0].starts.minutes} `), moment(`2022-03-01 ${element.periods[0].ends.hours}:${element.periods[0].ends.minutes}`)]}
                                                        value={[moment(`2022-03-01 ${element.periods[0].starts.hours}:${element.periods[0].starts.minutes} `), moment(`2022-03-01 ${element.periods[0].ends.hours}:${element.periods[0].ends.minutes}`)]}

                                                        onChange={(time,timeString) => onChangeTime(time,timeString,{day:element.day})}
                                                    />
                                                </Form.Item> 
                                            </Form.Item> 
                                        )
                                    )}
                                </div>
                          
                            
                            <div className="second-col qr-box ">
                                <h3>Branch QR-CODE</h3>
                                <div style={{ margin: "0px" }}>
                                    <Avatar
                                        size={160}
                                        src={getBranch.qrCode}
                                        icon={<UserOutlined />}
                                        shape="square"
                                        
                                    />
                                </div>
                                <Button onClick={onDownload} variant="contained" type="primary" shape="round" icon={<DownloadOutlined />} size="middle">
                                    Download
                                </Button>
                            </div>
                    </div>
                </div>
            </Form>
    )
}
