// import React, { useEffect, useState } from "react";
// import useAuth from '../auth/useAuth'
import { Link } from "react-router-dom";

import {
  SettingOutlined,
  BranchesOutlined,
  IdcardOutlined,
  DragOutlined,
  UserSwitchOutlined
} from "@ant-design/icons";
import {
  Tabs,
  Layout,
  Row,
  Col,
  Button
} from "antd";
import MainSettings from '../sub-routes-components/SettingSections/MainSettings.js'
import BranchSettings from '../sub-routes-components/SettingSections/BranchSettings.js'
import Branchs from "./SettingSections/Branchs";
import Subscriptions from './SettingSections/Subscriptions';
import Sections from './SettingSections/Sections';
import Employees from './SettingSections/Employees.js';
import {parseJwt} from '../commonFunctions';



const { Content } = Layout;
const { TabPane } = Tabs;

const settings = ['Account settings','Main Settings','Branch Settings','Branchs','Subscriptions','Sections Setting','Employees']



  
export default function SettingMenu({branch,company , token ,setCompany}) {
    const objectJWT = parseJwt(token);
    return(
        <Content className="sub-container" >
            <Row>
                <Col span={24}>
                    <Tabs className="sub-menu sub-component"  defaultActiveKey="1" tabPosition="left"  >
                        <TabPane  className="sum-menu-content" tab={
                            <div className="title-setting">
                                    <h1>{settings[0]}</h1>
                            </div>
                        }
                            key="0" disabled={1 == 1}>
                               
                        </TabPane>

                        <TabPane  tab={
                                    <span className="sub-title-setting-menu">
                                        <SettingOutlined />{settings[1]}
                                        <h6>Name, Logo, QR-Code, Menu</h6>
                                    </span>
                            } 
                            key="1"
                            >
                            <div >
                                <h2 className="title-name-of-setting-menu">{settings[1]}</h2>
                                <MainSettings company={company} token={token} />
                            </div>
                        </TabPane>

                        <TabPane  tab={
                                    <span className="sub-title-setting-menu">
                                        <BranchesOutlined />{settings[2]}
                                        <h6>Location, Area, WorkHours</h6>
                                    </span>
                            } 
                            key="2">
                                {branch === false ? 
                                (
                                    <div>
                                        <p>loading... create branch first</p>
                                        <Button
                                            type="primary"
                                            htmlType="button">
                                           <Link id="menuItem" to="/new-branch">Create Branch</Link>
                                        </Button>
                                        
                                    </div>
                                )
                                : 
                                (
                                    <div >
                                        <h2 className="title-name-of-setting-menu">{settings[2]}</h2>
                                        <BranchSettings  branch={branch} company={company} token={token} />
                                    </div>
                                )}
                        </TabPane>
                        {/* Branchs */}
                        {objectJWT.role === "owner" ?
                        (
                            <TabPane  tab={
                                        <span className="sub-title-setting-menu">
                                            <BranchesOutlined />{settings[3]}
                                            <h6>City, Area, CreatedAt</h6>
                                        </span>
                                } 
                                key="3">
                                        {company === false ? 
                                        (
                                            <p>loading...</p>
                                        )
                                        : 
                                        <div>
                                            <h2 className="title-name-of-setting-menu">{settings[3]}</h2>
                                            <Branchs tab={"BRANCHS"} token={token}/>
                                        </div>}                             
                            </TabPane>
                        )
                        :
                        (
                            ""   
                        )}
                        {/* Branchs End */}

                        <TabPane  tab={
                                    <span className="sub-title-setting-menu">
                                        <IdcardOutlined />{settings[4]}
                                        <h6>Plan, SMS</h6>
                                    </span>
                            } 
                            key="4">
                                {company === false ? 
                                (
                                    <p>loading...</p>
                                )
                                : 
                                (
                                    <div>
                                        <h2 className="title-name-of-setting-menu">{settings[4]}</h2>
                                        <Subscriptions tab={"SUBSCRIPTIONS"}   key="4" userSubs={company.subscription.services} smsSubs={company.subscription.sms} />
                                    </div>
                                )}                                
                        </TabPane>

                        {/* SECTIONS SETTING START */}
                        <TabPane  tab={
                                    <span className="sub-title-setting-menu">
                                        <DragOutlined />{settings[5]}
                                        <h6>Tables, Chairs</h6>
                                    </span>
                            } 
                            key="5">
                                    <div>
                                        <h2 className="title-name-of-setting-menu">{settings[5]}</h2>
                                        <Sections tab={"SECTIONS SETTING"} token={token} branch={branch}/>
                                    </div>
                        </TabPane> 
                        {/* SECTIONS SETTING END */}

                        {objectJWT.role === "owner" ?
                        (
                        <TabPane  tab={
                                    <span className="sub-title-setting-menu">
                                        <UserSwitchOutlined />{settings[6]}
                                        <h6>Waiter, Emails, Other Accounts</h6>
                                    </span>
                            } 
                            key="6">
                                    {company === false? 
                                        <p>Loadding...</p>
                                    :
                                    (
                                    <div>
                                        <h2 className="title-name-of-setting-menu">{settings[6]}</h2>
                                        <Employees  setCompany={setCompany} company={company} token={token}/>
                                    </div>
                                    )}
                        </TabPane>  
                        )
                        : 
                        (
                            ""
                        )} 
                                               
                    </Tabs>
                </Col>
            </Row>
        </Content>
         
    )


}
