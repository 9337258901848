import socketIOClient from "socket.io-client"
import { url } from "../api";

export default class SocketSingleton {

    static socket = null;

    // _socket = "";

    static getInstance() {
        if (SocketSingleton.socket == null) {
            SocketSingleton.socket = socketIOClient(url)
        }

        return this.socket;
    }

}